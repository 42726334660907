/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useParams, Routes, Route, Link as RouterLink } from 'react-router-dom';

import {
  DifferencesTable,
  PrettierTextArea,
  ModelTable,
  FloatingMapButton,
  EditorMenuButton,
  FuelTypeModelDownload,
  AreYouSureDialog,
} from 'components';
import { useNavigate } from 'react-router';

import { CircularProgress, Grid, Theme, Drawer, Toolbar, Typography, Link, Button, Card } from '@material-ui/core';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';

import { ChevronLeft, ChevronRight } from '@material-ui/icons';

import { getUserState } from 'utils';

import { useAppSelector, useAppDispatch } from 'hooks';
import { FuelTypeModelActions } from 'state/fueltypemodels';
import { ToastActions } from 'state/toast';
import { ToastType } from 'models/toast';

import { Common, FuelTypeModelsManager } from 'models';
import ValidationResultsTable from 'components/fueltypemodels/ValidationResultsTable';
import AddNote from 'components/fueltypemodels/AddNote';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    map: {
      width: '100%',
      height: '100%',
    },
    th: {
      fontWeight: 'bold',
      fontSize: 8,
    },
    smPad: {
      padding: 3,
    },
    drawer: {
      width: '50%',
      flexShrink: 0,
    },
    drawerPaper: {
      width: '50%',
    },
    drawerContent: {
      padding: theme.spacing(0),
      display: 'grid',
      gridTemplateRows: '1fr auto',
      overflowY: 'hidden',
      height: '100%',
    },
    drawerScrollable: {
      padding: theme.spacing(1),
      overflowY: 'auto',
    },
    drawerBottom: {
      padding: theme.spacing(0),
      borderTop: `1px solid ${theme.palette.common.neutralLight}`,
      backgroundColor: theme.palette.common.white,
      display: 'grid',
      placeItems: 'center',
      gridAutoFlow: 'column',
    },
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(5),
      color: theme.palette.text.primary,
    },
    subtitle: {
      fontWeight: 'bold',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      color: theme.palette.common.neutralDark, // neutralDark
    },
    sidebarfab: {
      color: theme.palette.common.grey,
      transform: 'rotate(90deg)',
      width: 'max-content',
      marginTop: 50,
    },
    sidebarfabicon: {
      color: theme.palette.common.grey,
    },
    topSection: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    button: {
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
      margin: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
      marginLeft: 0,
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
      fontWeight: 'bold',
      minWidth: 175,
      width: '-webkit-fill-available',
      // @ts-ignore This is for firefox
      width: '-moz-available',
    },
    link: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: `${theme.spacing(1)}px 0px`,
      padding: theme.spacing(2),
      border: `1px solid ${theme.palette.common.neutralXLight}`,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.neutralXLight,
        cursor: 'pointer',
      },
    },
    linkTitle: {
      fontWeight: 'bold',
    },
    linkSubtitle: {
      fontWeight: 'bold',
    },
    linkArrow: {
      color: theme.palette.common.neutral,
    },
    content: {
      flexGrow: 1,
      transition: `${theme.transitions.create('left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })}, ${theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })}, ${theme.transitions.create('top', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })}`,
      left: '0%',
    },
    group: {
      width: 'auto',
      height: 'auto',
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
    },
    menu: {
      marginTop: theme.spacing(5),
    },
    filterContainer: {
      display: 'flex',
      justifyContent: 'stretch',
    },
    textArea: {
      width: '100%',
      height: 300,
      border: 'none',
      padding: theme.spacing(2),
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      color: '#757575',
      fontSize: '16px',
      resize: 'none',
      outline: 0,
    },
    label: {
      color: theme.palette.common.neutralDark,
      marginBottom: theme.spacing(2),
    },
    filterButton: {
      width: '120px',
      fontWeight: 'bold',
      color: theme.palette.common.neutralDark,
    },
    filterButtonSelected: {
      width: '120px',
      fontWeight: 'bold',
      backgroundColor: theme.palette.common.white,
    },
    contentShift: {
      transition: `${theme.transitions.create('left', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })}, ${theme.transitions.create('height', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })}, ${theme.transitions.create('top', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })}`,
      left: '50%',
    },
  }),
);

function FSEAuthoriserFuelTypeModelWorkFlow() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { modelStatus: modelStatusRaw } = useParams<{ modelStatus: string }>();
  const modelStatus = modelStatusRaw ?? '';
  const { auth } = useAppSelector((state) => state);
  const { local } = useAppSelector((state) => state.fuelTypeModels);
  const { adhoc } = useAppSelector((state) => state.fuelTypeModels);
  const { valResultsAdHoc } = useAppSelector((state) => state.fuelTypeModels);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [userState, setuserState] = useState<Common.Jurisdictions | null>(getUserState(auth.object));
  const [displayMode, setDisplayMode] = useState<string>('model');
  const [differences, setDifferences] = useState<FuelTypeModelsManager.FuelTypeDifference[]>([]);
  const fuelTypeModels = local?.object?.data ?? [];
  const valResults = valResultsAdHoc?.object?.data ?? [];
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [noteMode, setNoteMode] = useState('menu'); // menu, addnote, or viewnote
  const [noteValue, setNoteValue] = useState('');

  const ftmsSubmitted = local?.object?.data.filter((x) => x.status === 'submitted')[0] ?? undefined;
  const fuelTypeModelsComp =
    modelStatus === 'new' ? adhoc?.object?.data : local?.object?.data.filter((x) => x.status === 'submitted');
  const fuelTypeModelsAuth = local?.object?.data.filter((x) => x.status === 'authorised');

  useEffect(() => {
    if (local.status === 'idle' && auth.status === 'finished') {
      if (auth?.object) {
        setuserState(getUserState(auth.object));
        dispatch(FuelTypeModelActions.getFuelTypeModelsForJurisdiction({ jurisdiction: userState || '' }));
      }
    }
  }, [auth?.object, auth.status, dispatch, local, userState]);

  const backLocation =
    window.location.pathname.endsWith('/notes') || window.location.pathname.endsWith('/requestedits')
      ? `/authoriser/fueltypemodels/${modelStatus}`
      : `/authoriser/fueltypemodels/dashboard`;

  const updateDrawer = (value: boolean) => {
    setDrawerOpen(value);
  };

  const handleChange = (active: string) => {
    setDisplayMode(active);
    if (active === 'differences') {
      if (fuelTypeModelsComp && fuelTypeModelsAuth) {
        const diffs = FuelTypeModelsManager.calculateDifferences(fuelTypeModelsComp, fuelTypeModelsAuth);
        setDifferences(diffs);
      }
    }
  };

  const handleAddNote = () => {
    setNoteMode('addnote');
    navigate('/authoriser/fueltypemodels/submitted/notes');
  };

  const handleViewNotes = () => {
    setNoteMode('viewnote');
    navigate('/authoriser/fueltypemodels/submitted/notes');
  };

  const handleAuthorise = async () => {
    console.log('handleAuthorise');
    // ToDo dispath and then get again
    dispatch(FuelTypeModelActions.approveFuelTypeModel({ note: '' }))
      .then(() => {
        dispatch(
          ToastActions.showToast({
            message: `Candidate fuel type model authorised successfully`,
            type: ToastType.success,
          }),
        );
      })
      .then(() => {
        // this would not be applicable to modelStatus: authorised
        dispatch(FuelTypeModelActions.getFuelTypeModelsForJurisdiction({ jurisdiction: userState || '' }));
      });
    navigate(`/authoriser/fueltypemodels/dashboard`);
  };

  const handleRequestEdits = async () => {
    console.log('handleRequestEdits');
    // ToDo dispatch and then get again
    dispatch(FuelTypeModelActions.rejectFuelTypeModel({ note: noteValue }))
      .then(() => {
        dispatch(
          ToastActions.showToast({
            message: `Candidate fuel type model edits successfully requested`,
            type: ToastType.success,
          }),
        );
      })

      .then(() => {
        // this would not be applicable to modelStatus: authorised
        dispatch(FuelTypeModelActions.getFuelTypeModelsForJurisdiction({ jurisdiction: userState || '' }));
      });
    navigate(`/authoriser/fueltypemodels/dashboard`);
  };

  const handleBackButton = () => {
    console.log('handleBackButton');
    setNoteMode('menu');
  };

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      <Drawer
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        onClose={() => updateDrawer(false)}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContent}>
          <div className={classes.drawerScrollable}>
            <div style={{ display: 'flex', margin: theme.spacing(2) }}>
              <Link
                component={RouterLink}
                to={backLocation}
                onClick={handleBackButton}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ display: 'flex', alignItems: 'center', color: theme.palette.common.neutralDark }}
                >
                  <ChevronLeft /> Back
                </Typography>
              </Link>

              {/* <AreYouSureDialog
                open={confirmOpen}
                onConfirm={() => {
                  setConfirmOpen(false);
                  handleAccept();
                }}
                onClose={() => setConfirmOpen(false)}
              /> */}
            </div>

            <Grid container>
              <Grid item xs={1} />
              <Grid item xs={10}>
                <>
                  {modelStatus !== 'new' && !window.location.pathname.endsWith('/requestedits') && (
                    <Typography variant="h5" className={classes.title}>
                      {`Review ${
                        modelStatus === 'submitted' ? 'Candidate' : capitalizeFirstLetter(modelStatus)
                      } Fuel Type Model`}
                    </Typography>
                  )}
                  {modelStatus !== 'new' && window.location.pathname.endsWith('/requestedits') && (
                    <Typography variant="h5" className={classes.title}>
                      {`Request Edits to ${
                        modelStatus === 'submitted' ? 'Candidate' : capitalizeFirstLetter(modelStatus)
                      } Fuel Type Model`}
                    </Typography>
                  )}
                  {modelStatus === 'new' && (
                    <Typography variant="h5" className={classes.title}>
                      {`Process ${capitalizeFirstLetter(modelStatus)} Fuel Type Model`}
                    </Typography>
                  )}
                  {modelStatus === 'submitted' && !window.location.pathname.endsWith('/requestedits') && (
                    <Typography variant="subtitle1" className={classes.subtitle}>
                      Review the model in the panel provided and add notes if required
                    </Typography>
                  )}
                  {modelStatus === 'authorised' && (
                    <Typography variant="subtitle1" className={classes.subtitle}>
                      Review the model in the panel provided and download locally if required
                    </Typography>
                  )}
                  {modelStatus === 'new' && (
                    <Typography variant="subtitle1" className={classes.subtitle}>
                      Select a fuel type model file to begin the review
                    </Typography>
                  )}
                </>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={1} />
              <Grid item xs={10}>
                <Routes>
                  <Route
                    path=":modelStatus"
                    element={
                      <>
                        {modelStatus === 'authorised' && (
                          <FuelTypeModelDownload
                            title="Download"
                            summary="Downloads the authorised FTM for the selected state"
                            localJurisdiction={userState ?? 'nsw'}
                          />
                        )}

                        {modelStatus === 'submitted' && (
                          <EditorMenuButton
                            title="Add Note"
                            summary="Appends a new note to the candidate fuel type model"
                            onClick={handleAddNote}
                          />
                        )}

                        {modelStatus === 'submitted' && (
                          <EditorMenuButton
                            title="View Notes"
                            summary="Display all candidate fuel type model notes"
                            onClick={handleViewNotes}
                          />
                        )}
                      </>
                    }
                  />

                  <Route
                    path="submitted/requestedits"
                    element={
                      userState && (
                        <Card>
                          <Typography className={classes.label}>Add Note</Typography>
                          <PrettierTextArea
                            className={classes.textArea}
                            placeholder="Type note..."
                            onChange={(e) => setNoteValue(e.target.value)}
                            minRows={10}
                            value={noteValue}
                          />
                        </Card>
                      )
                    }
                  />

                  <Route
                    path="submitted/notes"
                    element={
                      userState &&
                      ftmsSubmitted && (
                        <AddNote
                          modelId={ftmsSubmitted.id}
                          noteMode={noteMode}
                          fuelTypeModel={ftmsSubmitted}
                          modelStatus={modelStatus}
                          userState={userState}
                          userRole={window.location.pathname.includes('editor') ? 'editor' : 'authoriser'}
                        />
                      )
                    }
                  />
                </Routes>
              </Grid>
            </Grid>
          </div>
          <div className={classes.drawerBottom}>
            <Routes>
              <Route
                path="submitted"
                element={
                  <>
                    <Button
                      className={classes.button}
                      style={{ marginLeft: theme.spacing(3) }}
                      variant="contained"
                      onClick={() => navigate(`/authoriser/fueltypemodels/submitted/requestedits`)}
                    >
                      Request Edits
                    </Button>
                    <Button
                      className={classes.button}
                      style={{ marginLeft: theme.spacing(3) }}
                      disabled={local.status === 'loading' || local.status === 'error'}
                      variant="contained"
                      onClick={() => setConfirmOpen(true)}
                    >
                      Authorise
                      {local.status === 'loading' && (
                        <CircularProgress size={16} style={{ marginLeft: theme.spacing(2) }} aria-valuetext="loading" />
                      )}
                    </Button>

                    <AreYouSureDialog
                      open={confirmOpen}
                      onConfirm={() => {
                        setConfirmOpen(false);
                        if (handleAuthorise) handleAuthorise();
                      }}
                      onClose={() => setConfirmOpen(false)}
                    />
                  </>
                }
              />

              <Route
                path="submitted/requestedits"
                element={
                  <>
                    <Button
                      className={classes.button}
                      style={{ marginLeft: theme.spacing(3) }}
                      disabled={false}
                      variant="contained"
                      onClick={() => setConfirmOpen(true)}
                    >
                      Submit Edit Request
                      {/* {layer?.updateStatus.status === 'loading' && (
                  <CircularProgress size={16} style={{ marginLeft: theme.spacing(2) }} aria-valuetext="loading" />
                )} */}
                    </Button>
                    <AreYouSureDialog
                      open={confirmOpen}
                      onConfirm={() => {
                        setConfirmOpen(false);
                        if (handleRequestEdits) handleRequestEdits();
                      }}
                      onClose={() => setConfirmOpen(false)}
                    />
                  </>
                }
              />
            </Routes>
          </div>
        </div>
      </Drawer>

      <style>
        {`
        
        .ol-scale-line {
          background: white;
          filter: drop-shadow(0px 2px 4px ${theme.palette.common.neutral});
          padding: 4px;
          border-radius: 4px;
          bottom: 20px;
          left: 20px;
        }

        .ol-scale-line-inner {
          color: black;
          border: 2px solid black;
          border-top: none;
        }
        
        .ol-mouse-position {
          top: initial;
          pointer-events: auto;
          bottom: 20px;
          left: calc(50% - 80px);
          width: 160px;
          text-align: center;
          background-color: rgba(255,255,255,0.6);
          border-radius: 4px;
        }

        `}
      </style>

      <div
        className={`${classes.map}`}
        style={{ width: drawerOpen ? '50%' : '100%', marginLeft: drawerOpen ? 'calc(50% + 0px)' : '20px' }}
      >
        <div id="TableDiv" style={{ width: drawerOpen ? '100%' : '100%', marginLeft: drawerOpen ? '0' : '0' }} />

        {/* 
        TABLES AFTER HERE
        */}

        {modelStatus === 'submitted' && (
          <Grid container className={classes.menu}>
            <Grid item xs={1} />
            <Grid item xs={3}>
              <div className={classes.topSection}>
                <div className={classes.filterContainer}>
                  <Button
                    onClick={() => handleChange('model')}
                    className={displayMode === 'model' ? classes.filterButtonSelected : classes.filterButton}
                  >
                    Model
                  </Button>
                  <Button
                    onClick={() => handleChange('validation')}
                    className={displayMode === 'validation' ? classes.filterButtonSelected : classes.filterButton}
                  >
                    Validation
                  </Button>
                  <Button
                    onClick={() => handleChange('differences')}
                    className={displayMode === 'differences' ? classes.filterButtonSelected : classes.filterButton}
                  >
                    Differences
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        )}

        {displayMode === 'model' && modelStatus === 'authorised' && (
          <ModelTable
            tableStyle={{ marginLeft: 20 }}
            fuelTypeModels={fuelTypeModels}
            modelStatus={modelStatus}
            abbreviated={!!drawerOpen}
          />
        )}
        {displayMode === 'model' && modelStatus === 'submitted' && (
          <ModelTable
            tableStyle={{ marginLeft: 20 }}
            fuelTypeModels={fuelTypeModels}
            modelStatus={modelStatus}
            abbreviated={!!drawerOpen}
          />
        )}
        {displayMode === 'validation' && <ValidationResultsTable validationResults={valResults} />}
        {displayMode === 'differences' && <DifferencesTable differenceResults={differences} />}
      </div>

      <FloatingMapButton
        onClick={() => updateDrawer(!drawerOpen)}
        ariaLabel={`${drawerOpen ? 'open' : 'close'} side panel`}
        style={{ borderRadius: '0 4px 4px 0' }}
        buttonStyle={{ flexDirection: 'column', justifyContent: 'flex-start' }}
        width="25px"
        height={drawerOpen ? '25px' : '150px'}
        top={`calc(50% - ${drawerOpen ? '12.5px' : '75px'})`}
        left={drawerOpen ? '50%' : '0%'}
        className={`${classes.content} ${drawerOpen ? classes.contentShift : ''}`}
      >
        {drawerOpen ? (
          <ChevronLeft className={classes.sidebarfabicon} />
        ) : (
          <>
            <ChevronRight className={classes.sidebarfabicon} />
            <div className={classes.sidebarfab}>Show side panel</div>
          </>
        )}
      </FloatingMapButton>
    </>
  );
}

export default FSEAuthoriserFuelTypeModelWorkFlow;
