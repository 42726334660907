import { ErrorResp, ErrorTypes } from 'models';
import config from 'config';

export type UserGroups =
  | 'Observer'
  | 'Validator'
  | 'Editor'
  | 'Authoriser'
  | 'UserManager'
  | 'SystemAdmin'
  | 'HelpPublisher'
  | 'fdv-standard'
  | 'fdv-elevated'
  | 'nsw'
  | 'vic'
  | 'qld'
  | 'act'
  | 'tas'
  | 'sa'
  | 'nt'
  | 'wa';

export interface IdentityToken {
  'cognito:groups': UserGroups[];
  'cognito:username': string;
  given_name: string;
  family_name: string;
  email: string;
  email_verified: boolean;
}

export interface Auth {
  creds: Auth.OAuth2Creds | null;
  refresh_time?: Date | null;
  decoded_token?: IdentityToken | null;
}

export declare namespace Auth {
  /**
   * The standard OAuth2 Credentials Payload
   */
  export interface OAuth2Creds {
    access_token: string;
    token_type: string;
    expires_in: number;
    refresh_token?: string;
    scope: string;
    id_token: string;
  }
}

export async function revokeToken(token: string): Promise<number | ErrorResp> {
  try {
    const resp = await fetch(`${config.cognito_url}/revoke_token/`, {
      body: new URLSearchParams({
        token,
        client_id: config.client_id,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
    });
    if (!resp.ok) {
      return {
        error: resp.statusText,
        code: resp.status,
      };
    }

    return resp.status;
  } catch (err) {
    return {
      error: 'An error occured while sending the request',
      code: ErrorTypes.FAILED_TO_SEND_REQUEST,
      data: err,
    };
  }
}

export async function gotoAuth(): Promise<void> {
  const url = `${config.cognito_url}/oauth2/authorize?response_type=code&client_id=${
    config.client_id
  }&redirect_uri=${`${window.location.origin}/`}&state=${window.location.pathname}${window.location.search}`;
  window.location.href = url;
}

export async function logout(): Promise<void> {
  localStorage.removeItem(config.localstorage_auth);
  const url = `${config.cognito_url}/logout?response_type=code&client_id=${
    config.client_id
  }&redirect_uri=${`${window.location.origin}/`}`;
  window.location.href = url;
}

export async function applyRefresh(creds: Auth.OAuth2Creds): Promise<Auth.OAuth2Creds | ErrorResp> {
  try {
    if (creds == null || creds.refresh_token == null) {
      return {
        error: 'No refresh token provided',
        code: ErrorTypes.INVALID_CREDENTIALS,
      };
    }
    const resp = await fetch(`${config.cognito_url}/oauth2/token`, {
      body: new URLSearchParams({
        grant_type: 'refresh_token',
        refresh_token: creds.refresh_token,
        client_id: config.client_id,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
    });

    const respJson = await resp.json();

    if (!resp.ok) {
      if (resp.status === 400 && respJson?.error === 'invalid_grant') {
        console.log('Refresh Token Expired, redirecting to login page.');
        localStorage.removeItem(config.localstorage_auth);
        gotoAuth();
      }
      return {
        error: resp.statusText,
        code: resp.status,
      };
    }
    // This should respond with standard OAuth Creds
    return respJson;
  } catch (err) {
    return {
      error: 'An error occured while sending the request',
      code: ErrorTypes.FAILED_TO_SEND_REQUEST,
      data: err,
    };
  }
}

export async function getToken(access_token: string): Promise<Auth.OAuth2Creds | ErrorResp> {
  try {
    const resp = await fetch(`${config.cognito_url}/oauth2/token`, {
      body: new URLSearchParams({
        grant_type: 'authorization_code',
        code: access_token,
        client_id: config.client_id,
        redirect_uri: `${window.location.origin}/`,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
    });
    if (!resp.ok) {
      return {
        error: resp.statusText,
        code: resp.status,
      };
    }
    return await resp.json();
  } catch (err) {
    return {
      error: 'An error occured while sending the request',
      code: ErrorTypes.FAILED_TO_SEND_REQUEST,
      data: err,
    };
  }
}
