/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Typography, CircularProgress, Divider } from '@material-ui/core';

import { customColors } from 'theme';
import { MapLayerManager, FireAreaManager, Loadable, LocationManager, WmsManager, callWMS } from 'models';
import { FireAreaLocationItem } from 'components';
import { MapCommand, AddMarkers, ZoomToBounds } from 'components/map/MapCommands';
import { FeatureLike } from 'ol/Feature';
import CircleStyle from 'ol/style/Circle';
import { Stroke, Fill, Style, Text } from 'ol/style';
import { fuelConditionText } from 'models/location';
import CuringEditor from './CuringEditor';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    section: {
      padding: theme.spacing(1),
    },
    areaSegmentContainer: {
      display: 'grid',
      padding: `0px ${theme.spacing(2)}px`,
      gridAutoFlow: 'column',
      gridTemplateColumns: '200px 200px auto',
      alignItems: 'start',
      justifyItems: 'start',
      width: '100%',
    },
    areaSegment: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      width: '100%',
      height: '100%',
    },
    areaSegmentEnd: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      width: '100%',
      height: '100%',
      justifySelf: 'end',
      alignItems: 'flex-end',
      minWidth: 175,
    },
    areaSegmentTitle: {
      color: theme.palette.common.neutralDark, // theme.palette.common.neutralDark;
    },
    areaSegmentAge: {
      color: theme.palette.common.grey, // theme.palette.common.grey;
      fontWeight: 'bold',
    },
    areaSegmentMiddle: {
      display: 'flex',
      alignContent: 'center',
    },
    areaSegmentMiddleInner: {
      paddingRight: theme.spacing(3),
    },
    areaSegmentValue: {
      minWidth: '32px',
    },
  }),
);

const getValue = (location: LocationManager.Location, layer?: MapLayerManager.MapLayer) => {
  if (!layer) return -1;
  const observation = location.observations.find((x) => x.validated);
  if (!observation) return -1;
  switch (layer.type) {
    case 'grass-curing':
      return observation.curing || 0;
    case 'grass-fuel-condition':
      return observation.fuelCondition || 0;
    case 'grass-fuel-load':
      return observation.fuelLoad || 0;
    case 'fuel-type':
    case 'precandidate-fuel-type':
    case 'time-since-fire':
    case 'fire-history':
      return -1;
    default:
      return -1;
  }
};

const getLabel = (location: LocationManager.Location, layer?: MapLayerManager.MapLayer) => {
  const observation = location.observations.find((x) => x.validated);
  if (!observation) return '';
  switch (layer?.type) {
    case 'grass-curing':
      return `${location.name} ${observation.curing} ${observation.curing != null ? '%' : ''}`;
    case 'grass-fuel-condition':
      return `${location.name} ${fuelConditionText(observation.fuelCondition)}`;
    case 'grass-fuel-load':
      return `${location.name} ${observation.fuelLoad?.toFixed(1) ?? null}${
        observation.fuelLoad != null ? ' t/ha' : ''
      }`;
    default:
      return location.name;
  }
};

const styleFunc = (feature: FeatureLike) => {
  return new Style({
    image: new CircleStyle({
      radius: 10,
      stroke: new Stroke({ color: 'black', width: 5 }),
      fill: new Fill({ color: feature.get('meta') }),
    }),
    text: feature.get('text')
      ? new Text({
          font: '14px Roboto, Helvetica, Arial',
          text: feature.get('text'),
          overflow: true,
          fill: new Fill({
            color: customColors.white,
          }),
          stroke: new Stroke({
            color: 'black',
          }),
          backgroundFill: new Fill({
            color: 'rgba(0,0,0,0.7)',
          }),
          offsetY: -30,
          padding: [5, 4, 4, 5],
        })
      : undefined,
  });
};

interface WorkflowAreaDetailsProps {
  layer?: MapLayerManager.MapLayer | null;
  fireArea?: FireAreaManager.FireArea | null;
  status?: Loadable<null>['status'];
  mapDispatch?: (command: MapCommand) => void;
  layerId: string | null;
  operations?: MapLayerManager.MapLayer.StagedOperation[];
  onStageOperation: (operation: MapLayerManager.MapLayer.StagedOperation) => void;
  hideEditor?: boolean;
}

const lookUpColor = (value: number, legend: WmsManager.WMS.GetLegend.Entry[]) => {
  if (legend.length === 0) return 'black';
  let lastColour = legend[0].color;
  for (let i = 0; i < legend.length; i += 1) {
    const entry = legend[i];
    if (parseFloat(entry.quantity) > value) return lastColour;
    lastColour = entry.color;
  }
  return lastColour;
};

const WorkflowAreaDetails: React.FunctionComponent<WorkflowAreaDetailsProps> = ({
  fireArea,
  status,
  mapDispatch,
  layer,
  layerId,
  operations,
  onStageOperation,
  hideEditor,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [legendValue, setLegend] = useState<WmsManager.WMS.GetLegend.Entry[]>([]);

  const stat = fireArea?.statistics[0] ?? null;

  useEffect(() => {
    const makeRequest = async () => {
      if (layerId) {
        const query = WmsManager.makeGetLegendQuery({ layer: layerId });
        const response = await callWMS<WmsManager.WMS.GetLegend.Root>(query);
        const entries = response?.Legend[0]?.rules[0]?.symbolizers[0]?.Raster?.colormap?.entries;
        setLegend(entries || []);
      }
    };
    makeRequest();
  }, [layerId]);

  useEffect(() => {
    const coords =
      fireArea?.locations.map((x) => {
        const value = getValue(x, layer || undefined);
        return {
          coords: [x.lon, x.lat],
          id: x.id,
          text: getLabel(x, layer || undefined),
          meta: lookUpColor(value || 0, legendValue),
        };
      }) || [];
    const addMarkersCommand = new AddMarkers(coords, undefined, styleFunc);
    if (mapDispatch) {
      mapDispatch(addMarkersCommand);
      if (fireArea) mapDispatch(new ZoomToBounds(fireArea.bounds));
    }
    return () => {
      if (addMarkersCommand.remove) addMarkersCommand.remove();
    };
  }, [mapDispatch, fireArea, layer, legendValue]);

  const getUnits = () => {
    switch (layer?.name) {
      case 'Grass Fuel Condition':
        return '';
        break;
      case 'Grass Fuel Load':
        return ' t/ha';
        break;
      default:
        return '%';
    }
  };

  const locationList =
    layer != null
      ? fireArea?.locations.map((location) => (
          <FireAreaLocationItem key={location.id} location={location} layer={layer} />
        ))
      : [];

  return (
    <div className={classes.root}>
      <Typography variant="h5" style={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
        {layer?.name ? `${layer.name} area details` : 'Invalid layer'}
      </Typography>
      {!!fireArea && (
        <div className={classes.section}>
          <div className={classes.areaSegmentContainer}>
            <div className={classes.areaSegment}>
              <div className={classes.areaSegmentMiddle}>
                <Typography variant="h6" className={classes.areaSegmentValue} style={{ fontWeight: 'bold' }}>
                  {fireArea?.name}
                </Typography>
              </div>
            </div>

            <div className={classes.areaSegment}>
              <Typography variant="subtitle2" className={classes.areaSegmentTitle}>
                {layer?.type === 'grass-curing' ? 'Grass Curing' : layer?.name}
              </Typography>
              <div style={{ display: 'flex' }}>
                <div className={classes.areaSegmentMiddleInner}>
                  <div className={classes.areaSegmentMiddle}>
                    <Typography variant="h5" className={classes.areaSegmentValue}>
                      {stat?.percentile10 ? `${stat.percentile10}${getUnits()}` : 'N/A'}
                    </Typography>
                  </div>
                  <Typography variant="subtitle2" className={classes.areaSegmentAge}>
                    10th
                  </Typography>
                </div>
                <div className={classes.areaSegmentMiddleInner}>
                  <div className={classes.areaSegmentMiddle}>
                    <Typography variant="h5" className={classes.areaSegmentValue}>
                      {stat?.percentile50 ? `${stat.percentile50}${getUnits()}` : 'N/A'}
                    </Typography>
                  </div>
                  <Typography variant="subtitle2" className={classes.areaSegmentAge}>
                    50th
                  </Typography>
                </div>
                <div className={classes.areaSegmentMiddleInner}>
                  <div className={classes.areaSegmentMiddle}>
                    <Typography variant="h5" className={classes.areaSegmentValue}>
                      {stat?.percentile90 ? `${stat.percentile90}${getUnits()}` : 'N/A'}
                    </Typography>
                  </div>
                  <Typography variant="subtitle2" className={classes.areaSegmentAge}>
                    90th
                  </Typography>
                </div>
              </div>
            </div>

            <div className={classes.areaSegmentEnd}>
              {layer?.name && layer?.name !== 'Grass Fuel Condition' && layer?.name !== 'Grass Fuel Load' && (
                <div style={{ padding: `0px ${theme.spacing(2)}px` }}>
                  <Typography variant="subtitle2" className={classes.areaSegmentTitle}>
                    Satellite vs. field
                  </Typography>
                  <div className={classes.areaSegmentMiddle}>
                    <Typography variant="h5" className={classes.areaSegmentValue}>
                      {stat?.satelliteFieldDiff ? `${stat.satelliteFieldDiff}%` : 'N/A'}
                    </Typography>
                  </div>
                  <Typography variant="subtitle2" className={classes.areaSegmentAge}>
                    Max difference
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {!!fireArea && !hideEditor && layer && layer.type === 'grass-curing' && (
        <div className={classes.section}>
          <CuringEditor fireArea={fireArea} layer={layer} onStageOperation={onStageOperation} />
        </div>
      )}
      {!!fireArea && operations && operations.length > 0 && (
        <div className={classes.section}>
          <Typography variant="subtitle2" style={{ fontWeight: 'bold', color: theme.palette.common.neutralDark }}>
            {operations.length} edits made to this district
          </Typography>
        </div>
      )}
      {!!fireArea && <Divider style={{ marginTop: 25, marginBottom: 25 }} />}
      {!!fireArea && (
        <div className={classes.section}>
          <Typography variant="subtitle2" style={{ fontWeight: 'bold', color: theme.palette.common.neutralDark }}>
            Showing {fireArea?.locations.length || 0} locations
          </Typography>
        </div>
      )}
      {!!fireArea && <div className={classes.section}>{locationList}</div>}
      {status && status === 'finished' && !fireArea ? (
        <div className={classes.section} style={{ display: 'grid', placeItems: 'center' }}>
          <Typography variant="h6"> Failed to load Map Layer and Fire Management Area data </Typography>
        </div>
      ) : undefined}
      {status && (status === 'idle' || status === 'loading') ? (
        <div className={classes.section} style={{ display: 'grid', placeItems: 'center' }}>
          <CircularProgress size={20} aria-valuetext="loading" />
        </div>
      ) : undefined}
    </div>
  );
};

export default WorkflowAreaDetails;
