import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Button, CircularProgress, Divider, Grid, Switch, TextField, Theme, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { getUserState } from 'utils';
import { useAppSelector, useAppDispatch } from 'hooks';

import { UserActions } from 'state/user';
import { ToastActions } from 'state/toast';
import { AuthUserActions } from 'state/authuser';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton } from 'components';
import RedButton from 'components/locationadmin/RedButton';
import { ToastManager } from 'models';
import authDomainDataLoader from 'utils/authDomainHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(5),
      color: theme.palette.text.primary,
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(7),
    },
    paddedField: {
      marginBottom: theme.spacing(2),
    },
  }),
);

type GroupsType = {
  [key: string]: string[];
};
const groups: GroupsType = {
  Authoriser: ['Editor', 'Validator', 'Observer'],
  Editor: ['Validator', 'Observer'],
  Validator: ['Observer'],
  Observer: [],
  UserManager: [],
  HelpPublisher: [],
  'fdv-elevated': ['fdv-standard'],
  'fdv-standard': [],
};

const AdminEditUser = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { auth, user } = useAppSelector((state) => state);
  const { editUser } = useAppSelector((state) => state.users);
  const { userId: userIdRaw } = useParams<{ userId: string }>();
  const userId = userIdRaw ?? '';
  const isSysAdmin = auth.object?.decoded_token?.['cognito:groups']
    ? auth.object.decoded_token['cognito:groups'].indexOf('SystemAdmin') > -1
    : false;
  const [firstnameValue, setFirstnameValue] = useState(editUser.object?.given_name);
  const [lastnameValue, setLastnameValue] = useState(editUser.object?.family_name);
  const [emailValue, setEmailValue] = useState(editUser.object?.user_email);
  const [runCognitoAction, setCognitoAction] = useState(false);
  const [groupsValue, setGroupsValue] = useState<string[]>(
    editUser.object?.roles.map((x) => x.role_name).filter((x) => Object.keys(groups).includes(x)) || [],
  );
  const [loadingValue, setLoading] = useState(false);
  const [authProvIsOkta, setAuthProvIsOkta] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const id = parseInt(userId, 10);
    if (
      auth.status === 'finished' &&
      (editUser.status === 'idle' ||
        (editUser.status === 'error' && editUser.object?.user_id !== id) ||
        (editUser.status === 'finished' && editUser.object?.user_id !== id))
    ) {
      if (auth?.object) {
        dispatch(UserActions.getEditUser({ user_id: id }));
      }
    }

    if (editUser.status === 'finished') {
      setFirstnameValue(editUser.object?.given_name);
      setLastnameValue(editUser.object?.family_name);
      setEmailValue(editUser.object?.user_email);
      setGroupsValue(
        editUser.object?.roles.map((x) => x.role_name).filter((x) => Object.keys(groups).includes(x)) || [],
      );
    }
  }, [
    auth?.object,
    auth.status,
    dispatch,
    editUser.status,
    history,
    userId,
    editUser.object?.user_id,
    editUser.object?.given_name,
    editUser.object?.family_name,
    editUser.object?.user_email,
    editUser.object?.roles,
    setFirstnameValue,
    setLastnameValue,
    setEmailValue,
    setGroupsValue,
  ]);

  // Determine if this is an okta user
  useEffect(() => {
    authDomainDataLoader(dispatch).then((result) => {
      // Get the authDomain data that is to do with Okta
      let badProviderId = -1; // 2 = COGNITO = default <-- -1 isn't used I think
      for (const authData of result) {
        if (authData.provider_type.includes('OKTA')) {
          badProviderId = authData.provider_id;
          break;
        }
      }

      // Check if the users provider id is OKTA
      if (badProviderId == editUser.object?.identity_provider_id) {
        setAuthProvIsOkta(true);
      }
    });
  });

  if (editUser.status === 'loading') {
    return (
      <div style={{ textAlign: 'center', marginTop: theme.spacing(5) }}>
        <CircularProgress aria-valuetext="loading" />
      </div>
    );
  }

  console.log(user);
  console.log(editUser);

  const isValid = [firstnameValue, lastnameValue, groupsValue.length].filter((x) => !x).length === 0;
  const getCognitoAction = () => {
    const defAction = 'DoNothing';
    // Hard code the method to return `DoNothing` if the user's auth provider is Okta
    if (authProvIsOkta) {
      return defAction;
    }

    if (runCognitoAction) {
      if (editUser.object && editUser.object.cognito_status === 'FORCE_CHANGE_PASSWORD') return 'ResendInvitation';
      if (editUser.object && editUser.object.cognito_status === 'CONFIRMED') return 'ResetPassword';
    }
    return defAction;
  };

  if (!editUser.object) {
    return <>User not found</>;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCognitoAction(event.target.checked);
  };
  const handleSubmit = async () => {
    setLoading(true);
    try {
      await dispatch(
        UserActions.updateUser({
          user: {
            family_name: lastnameValue || '',
            cognito_action: getCognitoAction(),
            given_name: firstnameValue || '',
            groups: groupsValue,
            user_email: editUser.object?.user_email ?? '',
            user_name: editUser.object?.user_email ?? '',
            user_id: parseInt(userId, 10),
            auth_provider_is_okta: authProvIsOkta,
          },
        }),
      ).then(() => dispatch(UserActions.getEditUser({ user_id: parseInt(userId, 10) })));
      dispatch(
        ToastActions.showToast({
          message: `${firstnameValue} ${lastnameValue} updated`,
          type: ToastManager.ToastType.success,
        }),
      );
      const userState = getUserState(auth?.object);
      await dispatch(UserActions.getUsersForJurisdiction({ jurisdiction: userState || '' }));

      if (user.user.object?.userName === editUser.object?.user_name) {
        await dispatch(AuthUserActions.getSelf());
      }

      navigate('/admin/users');
    } catch (e: any) {
      dispatch(
        ToastActions.showToast({
          message: e?.data?.detail || `Error encountered updating user`,
          type: ToastManager.ToastType.error,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await dispatch(UserActions.deleteUser({ username: editUser.object?.user_name ?? '' }));
      ToastActions.showToast({
        message: 'User deleted',
        type: ToastManager.ToastType.success,
      });
    } catch (e: any) {
      await dispatch(
        ToastActions.showToast({
          message: e?.data?.detail || 'Error when attempting to delete user',
          type: ToastManager.ToastType.error,
        }),
      );
      return;
    }
    const userState = getUserState(auth?.object);
    await dispatch(UserActions.getUsersForJurisdiction({ jurisdiction: userState || '' }));
    await dispatch(
      ToastActions.showToast({
        message: `${editUser.object?.given_name} ${editUser.object?.family_name} deleted`,
        type: ToastManager.ToastType.success,
      }),
    );
    navigate('/admin/users');
  };

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const group = event.target.name;
    const changeEl = document.getElementsByName(group)[0] as HTMLInputElement;
    console.log(`trigger group is: ${group} value is ${changeEl.checked}`);
    let groupsValueNew = [...groupsValue];

    // Do all the checked ones
    const groupNames = Object.keys(groups);
    for (let i = 0; i < groupNames.length; i += 1) {
      const inputEl = document.getElementsByName(groupNames[i])[0] as HTMLInputElement;
      if (inputEl.checked) {
        if (!groupsValueNew.includes(groupNames[i])) {
          groupsValueNew.push(groupNames[i]);
          console.log(`added group: ${groupNames[i]}`);
        }

        const subGrps = groups[groupNames[i]];
        for (let j = 0; j < subGrps.length; j += 1) {
          const subinputEl = document.getElementsByName(subGrps[j])[0] as HTMLInputElement;
          subinputEl.checked = true;
          if (!groupsValueNew.includes(subGrps[j])) {
            groupsValueNew.push(subGrps[j]);
            console.log(`added sub group: ${subGrps[j]}`);
          }
        }
      }
    }

    // Remove any unchecked ones
    for (let i = 0; i < groupNames.length; i += 1) {
      const inputEl = document.getElementsByName(groupNames[i])[0] as HTMLInputElement;
      if (!inputEl.checked) {
        if (groupsValueNew.includes(groupNames[i])) {
          groupsValueNew = groupsValueNew.filter((s) => s !== groupNames[i]);
          console.log(`removed group: ${groupNames[i]}`);
        }
      }
    }

    // Set the state
    setGroupsValue(groupsValueNew);
  };

  const renderGroup = (group: string) => {
    // const checked = editUser.object?.roles.some((e) => e.role_name === group);
    const checked = groupsValue.includes(group);

    return (
      <div className={classes.paddedField} key={group}>
        <Switch
          disabled={group === 'SystemAdmin' && !isSysAdmin}
          key={group}
          checked={checked}
          onChange={handleCheckChange}
          color="primary"
          name={group}
        />
        {group}
      </div>
    );
  };

  return (
    <>
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography variant="h4" className={classes.title}>
            {editUser.object?.given_name} {editUser.object?.family_name}
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={4}>
              <h4>Details</h4>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={6}>
              <h4>Groups</h4>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={4}>
              <TextField
                disabled
                className={classes.paddedField}
                label="Email"
                variant="outlined"
                value={emailValue}
                fullWidth
              />
              <TextField
                className={classes.paddedField}
                label="Firstname"
                variant="outlined"
                value={firstnameValue}
                fullWidth
                placeholder="John"
                onChange={(e) => setFirstnameValue(e.target.value)}
                disabled={authProvIsOkta}
              />
              <TextField
                className={classes.paddedField}
                label="Lastname"
                variant="outlined"
                value={lastnameValue}
                fullWidth
                placeholder="Smith"
                onChange={(e) => setLastnameValue(e.target.value)}
                disabled={authProvIsOkta}
              />
              {editUser.object && editUser.object?.cognito_status === 'FORCE_CHANGE_PASSWORD' && (
                <FormControlLabel
                  control={<Checkbox color="primary" name="cognitoActionCheck" onChange={handleChange} />}
                  label="Resend Invitation"
                />
              )}
              {editUser.object && editUser.object?.cognito_status === 'CONFIRMED' && (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="cognitoActionCheck"
                      onChange={handleChange}
                      disabled={authProvIsOkta}
                    />
                  }
                  label="Reset Password"
                />
              )}
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              {Object.keys(groups).map(renderGroup)}
            </Grid>

            <Grid item xs={12}>
              <PrimaryButton
                disabled={!isValid}
                size="large"
                onClick={handleSubmit}
                loading={loadingValue}
                style={{ width: 150 }}
              >
                Update
              </PrimaryButton>{' '}
              <RedButton
                size="large"
                onClick={handleDelete}
                style={{ width: 150, marginTop: 0 }}
                disabled={authProvIsOkta}
              >
                Delete
              </RedButton>{' '}
              <Button size="large" onClick={() => navigate('/admin/users')} style={{ width: 150 }}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminEditUser;
