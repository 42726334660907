import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';

import thunk from 'redux-thunk';

import { authReducer } from 'state/auth';
import { authUserReducer } from 'state/authuser';
import { oberseverReducer } from 'state/observer';
import { validatorReducer } from 'state/validator';
import { editorReducer } from 'state/editor';
import { authoriserReducer } from 'state/authoriser';
import { userLocationReducer } from 'state/userlocation';
import { locationReducer } from 'state/location';
import { userReducer } from 'state/user';
import { toastReducer } from 'state/toast';
import { layerReducer } from 'state/layers';
import { incidentReducer } from 'state/incidents';
import { meteogramReducer } from 'state/meteograms';
import { fuelTypeModelReducer } from 'state/fueltypemodels';
import { dangerRatingsReducer } from 'state/dangerratings';
import { helpReducer } from 'state/help';
import { archiveReducer } from 'state/archive';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: authUserReducer,
    observer: oberseverReducer,
    validator: validatorReducer,
    editor: editorReducer,
    authoriser: authoriserReducer,
    position: userLocationReducer,
    location: locationReducer,
    users: userReducer,
    toast: toastReducer,
    layers: layerReducer,
    incidents: incidentReducer,
    meteograms: meteogramReducer,
    fuelTypeModels: fuelTypeModelReducer,
    dangerRatings: dangerRatingsReducer,
    help: helpReducer,
    archive: archiveReducer,
  },
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

/**
 * Intended to be used by tests
 * @param state The inital state of the Redux App
 * @returns A new redux store, with an initial state
 */
export const makeStoreWithState = (state?: ConfigureStoreOptions<RootState>['preloadedState']) =>
  configureStore({
    reducer: {
      auth: authReducer,
      user: authUserReducer,
      observer: oberseverReducer,
      validator: validatorReducer,
      editor: editorReducer,
      authoriser: authoriserReducer,
      position: userLocationReducer,
      location: locationReducer,
      users: userReducer,
      toast: toastReducer,
      layers: layerReducer,
      incidents: incidentReducer,
      meteograms: meteogramReducer,
      fuelTypeModels: fuelTypeModelReducer,
      dangerRatings: dangerRatingsReducer,
      help: helpReducer,
      archive: archiveReducer,
    },
    middleware: [thunk],
    preloadedState: state,
  });

export default store;
