import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Button } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterButton: {
      fontWeight: 'bold',
      color: theme.palette.common.neutralDark,
      margin: theme.spacing(1),
      marginRight: theme.spacing(2),
      textTransform: 'none',
    },
    filterButtonSelected: {
      fontWeight: 'bold',
      backgroundColor: theme.palette.common.white,
      margin: theme.spacing(1),
      marginRight: theme.spacing(2),
      textTransform: 'none',
    },
    break: {
      display: 'inline-block',
      marginRight: theme.spacing(2),
      borderRight: `1px solid ${theme.palette.common.neutralXLight}`,
      width: 1,
      height: 62,
      paddingTop: 24,
    },
  }),
);

interface IProps {
  options: string[];
  colors?: string[];
  onClick: (option: string) => void;
  activeIndex: string;
}

const ButtonTabs: React.FunctionComponent<IProps> = ({ options, onClick, activeIndex, colors }) => {
  const classes = useStyles();
  return (
    <div style={{ height: '100%' }}>
      {options.map((option, index) => {
        if (!option) {
          const key = index.toString();
          return (
            <div key={key} className={classes.break}>
              &nbsp;
            </div>
          );
        }
        const active = activeIndex === option;
        return (
          <Button
            key={option}
            variant={active ? 'contained' : 'text'}
            onClick={() => onClick(option)}
            className={active ? classes.filterButtonSelected : classes.filterButton}
            style={
              colors?.[index]
                ? {
                    borderColor: colors?.[index] ?? 'unset',
                    borderWidth: 2,
                    borderStyle: 'solid',
                  }
                : undefined
            }
          >
            {option}
          </Button>
        );
      })}
    </div>
  );
};

export default ButtonTabs;
