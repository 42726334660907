import React, { useEffect, useRef } from 'react';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import {
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  TextField,
} from '@material-ui/core';

import { useAppSelector, useAppDispatch } from 'hooks';
import { getUserState } from 'utils';

import * as UserActions from 'state/user';
import UserListItem from 'components/useradmin/UserListItem';
import { PrimaryButton } from 'components';
import { useNavigate } from 'react-router-dom';
import { UserManager } from 'models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(5),
      color: theme.palette.text.primary,
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(7),
    },
    searchBarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    searchBarStyle: {
      width: '350px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    th: {
      fontWeight: 'bold',
    },
  }),
);

const useStylesTablePag = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }),
);

/**
 * Timeout for the search bar debounce (in milliseconds)
 */
const DEBOUNCE_TIMEOUT = 600;

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStylesTablePag();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const AdminListUsers = () => {
  let tableRows;

  const classes = useStyles();
  const { users, auth } = useAppSelector((state) => state);

  const [jurisdiction, setJurisdiction] = React.useState('');
  const [fuzzyQuery, setFuzzyQuery] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const theme = useTheme();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const valueRef = useRef('');

  useEffect(() => {
    if (auth.status === 'finished' && jurisdiction === '') {
      setJurisdiction(getUserState(auth?.object) || '');
    }
  }, [auth?.object, auth.status, setJurisdiction, jurisdiction]);

  useEffect(() => {
    if (jurisdiction !== '') {
      dispatch(UserActions.getUsersForJurisdiction({ jurisdiction, page, size: rowsPerPage, search: fuzzyQuery }));
    }
  }, [dispatch, jurisdiction, rowsPerPage, page, fuzzyQuery]);

  if (auth.status === 'loading') {
    return (
      <div style={{ textAlign: 'center', marginTop: theme.spacing(5) }}>
        <CircularProgress aria-valuetext="loading" />
      </div>
    );
  }

  let timeoutHandle: ReturnType<typeof setTimeout>;
  let searchBarValue: string;

  /**
   * Handler for the debounce timeout
   */
  const queryFuzzySearchAPI = () => {
    setFuzzyQuery(searchBarValue);
    setPage(0);
  };

  const handleTextInput = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // Clear any timeout that might be running
    if (timeoutHandle != null) {
      clearTimeout(timeoutHandle);
    }

    // Value that we got from the bar
    searchBarValue = event.target.value;

    // Set a timeout
    timeoutHandle = setTimeout(queryFuzzySearchAPI, DEBOUNCE_TIMEOUT);
  };

  const handleNewUserClick = () => navigate(`/admin/users/new`);
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const total = users.users.object?.total || 0;
  const emptyRows = rowsPerPage - (users.users.object?.users?.length || 0);

  if (users.users?.status === 'loading') {
    tableRows = (
      <TableRow>
        <TableCell colSpan={4} style={{ height: 69 * rowsPerPage }}>
          <div style={{ textAlign: 'center', marginTop: theme.spacing(5) }}>
            <CircularProgress aria-valuetext="loading" />
          </div>
        </TableCell>
      </TableRow>
    );
  } else {
    tableRows = (
      <>
        {(users?.users.object?.users || []).map((u: UserManager.User.User) => (
          <UserListItem key={u.user_id} user={u} />
        ))}
        {emptyRows > 0 && (
          <TableRow style={{ height: 69 * emptyRows }}>
            <TableCell colSpan={4} />
          </TableRow>
        )}
      </>
    );
  }

  return (
    <>
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography variant="h4" className={classes.title}>
            {jurisdiction.toUpperCase()}: {total} users
          </Typography>
          <div className={classes.searchBarContainer}>
            <div className={classes.searchBarStyle}>
              <div style={{ width: 300 }}>
                <TextField
                  fullWidth
                  id="filled-basic"
                  inputRef={valueRef}
                  label="Search"
                  variant="filled"
                  onChange={(event) => handleTextInput(event)}
                />
              </div>
              {users.users?.status === 'loading' && (
                <div style={{ marginLeft: 5 }}>
                  <CircularProgress />
                </div>
              )}
            </div>
            <div>
              <PrimaryButton onClick={handleNewUserClick}>Invite new user</PrimaryButton>
            </div>
          </div>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <TableContainer component={Paper} elevation={0}>
            <Table aria-label="users">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.th}>Name</TableCell>
                  <TableCell className={classes.th}>Email</TableCell>
                  <TableCell className={classes.th}>Groups</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>{tableRows}</TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    colSpan={4}
                    count={users.users?.object?.total || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    style={{ padding: 0, margin: 0 }}
                    SelectProps={{
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminListUsers;
