/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Dialog, DialogTitle, DialogActions, DialogContentText, DialogContent } from '@material-ui/core';
import PrimaryButton from './PrimaryButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `1px solid ${theme.palette.common.neutralXLight}`,
      backgroundColor: theme.palette.common.white,
    },
    button: {
      marginLeft: theme.spacing(2),
    },
  }),
);

interface AreYouSureDialogProps {
  onConfirm?: (data?: any) => void;
  onClose?: (data?: any) => void;
  open: boolean;
  data?: any;
  message?: string;
}

const AreYouSureDialog: React.FunctionComponent<AreYouSureDialogProps> = ({
  onClose,
  onConfirm,
  open,
  data,
  message,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={() => onClose && onClose(data)} fullWidth maxWidth="xs">
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent>
        <DialogContentText>{message ?? 'Are you sure?'} </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PrimaryButton className={classes.button} onClick={() => onClose && onClose(data)}>
          No
        </PrimaryButton>
        <PrimaryButton className={classes.button} onClick={() => onConfirm && onConfirm(data)} autoFocus>
          Yes
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

AreYouSureDialog.displayName = 'AreYouSureDialog';
export default AreYouSureDialog;
