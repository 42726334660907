/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import {
  Theme,
  Typography,
  Divider,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  FormControl,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { useAppDispatch } from 'hooks';
import { CarouselSlides, LocationManager } from 'models';
import { ValidatorActions } from 'state/validator';
import { PreviousValue, PrettierTextArea } from 'components';

import { useAppSelector } from 'hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
      verticalAlign: 'middle',
      marginTop: theme.spacing(1),
    },
    close: {
      marginLeft: 'auto',
      color: theme.palette.common.neutralDark, // neutralDark
      textDecoration: 'underline',
    },
    title: {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
      padding: theme.spacing(0),
    },
    subtitle: {
      color: theme.palette.common.neutralDark, // neutralDark
      fontWeight: 'bold',
      padding: theme.spacing(1),
    },
    titleArea: {
      width: '100%',
      display: 'flex',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
    section: {
      padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    },
    doubleColumnSection: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: '1fr 1fr',
    },
    mobileSection: {
      display: 'grid',
    },
    column: {
      marginRight: theme.spacing(0.5),
    },
    formControl: {
      width: '100%',
    },
    textArea: {
      backgroundColor: theme.palette.common.neutralXXLight,
    },
    textAreaText: {
      fontSize: '16px',
    },
  }),
);

const useCheckboxStyles = makeStyles((theme: Theme) =>
  createStyles({
    checked: {
      '&$checked': {
        color: theme.palette.common.green,
      },
    },
  }),
);

interface EditFieldDialogProps {
  locationId: number;
  fieldTitle?: string;
  field: keyof LocationManager.Location.Observation;
  observationId: number;
  currentValue: number | null;
  currentValueText: string;
  currentValueTextOriginal: string;
  prevValueText?: string;
  prevValueTime?: number;
  observerName: string;
  options?: CarouselSlides[];
  open: boolean;
  units?: string;
  helpText?: string;
  isValid?: (value: string) => boolean;
  onClose: () => void;
}

const EditFieldDialog: React.FunctionComponent<EditFieldDialogProps> = ({
  locationId,
  open,
  onClose,
  fieldTitle,
  field,
  observationId,
  currentValue,
  currentValueText,
  currentValueTextOriginal,
  prevValueText,
  prevValueTime,
  observerName,
  options,
  units,
  helpText,
  isValid,
}) => {
  const classes = useStyles();
  const checkBoxClasses = useCheckboxStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const location = useAppSelector(
    (state) =>
      state.validator.locations.object && state.validator.locations.object.find((loc) => loc.id === locationId),
  );

  const [value, setValue] = useState<number | null>(null);
  const [nullSelected, setNullSelected] = useState(currentValue === null);
  const [oldValue, setOldValue] = useState<number | null>(currentValue);

  const onChangeValue = (newValue: number | null | undefined) => {
    if (location)
      dispatch(
        ValidatorActions.modifyObservation({
          id: location.id,
          current: {
            [field]: newValue,
          },
        }),
      );
  };
  const onChangeNote = (note: string) => {
    if (location)
      dispatch(
        ValidatorActions.modifyObservation({
          id: location.id,
          current: {
            newNote: {
              note,
            },
          },
        }),
      );
  };
  const onChangeNotify = (notify: boolean) => {
    if (location)
      dispatch(
        ValidatorActions.modifyObservation({
          id: location.id,
          current: {
            notifyChange: notify,
          },
        }),
      );
  };

  const handleChange = (event: any) => {
    const newValue = event?.target ? event?.target?.value ?? 'null' : event;
    if (options) {
      onChangeNote(
        `${fieldTitle} changed from ${currentValueText} to ${
          options.find((option) => option.value === newValue)?.title ?? 'null'
        }`,
      );
    } else {
      onChangeNote(`${fieldTitle} changed from ${currentValueText} to ${newValue}${newValue != null ? units : ''}`);
    }

    if (newValue != null) setValue(newValue);
    onChangeValue(newValue);
  };

  const handleSubmit = () => {
    if (location) {
      dispatch(ValidatorActions.submitEdits({ location, id: observationId })).then(() => {
        dispatch(ValidatorActions.getLocations());
        onClose();
      });
    } else {
      onClose();
    }
  };

  const handleOnClose = () => {
    onChangeValue(undefined);
    onClose();
  };

  useEffect(() => {
    setValue(currentValue);
    setNullSelected(currentValue === null);
  }, []);

  useEffect(() => {
    setValue(currentValue);
    setNullSelected(currentValue === null);
  }, [currentValue]);

  useEffect(() => {
    // When the field or locationId changes, the null checkbox needs to be reset
    setNullSelected(currentValue === null);
  }, [field, locationId]);

  return (
    <Dialog open={open} onClose={handleOnClose} maxWidth="md" fullWidth aria-labelledby="form-dialog-title">
      <div className={classes.titleArea}>
        <DialogTitle id="form-dialog-title" className={classes.title}>
          <span style={{ color: theme.palette.common.neutralDark /* neutralDark */ }}>{location?.name} /</span>{' '}
          {fieldTitle}
        </DialogTitle>
        <Button className={classes.close} onClick={handleOnClose} variant="text">
          <Close /> Close
        </Button>
      </div>
      <Divider />
      <DialogContent>
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            {prevValueText && prevValueTime && (
              <div className={classes.section}>
                <PreviousValue
                  text={prevValueText}
                  time={prevValueTime}
                  style={{ display: 'block' }}
                  textStyle={{ color: theme.palette.common.neutralDark /* neutralDark */ }}
                />
              </div>
            )}
            <div className={`${classes.section} ${isMobile ? classes.mobileSection : classes.doubleColumnSection}`}>
              <div className={classes.column}>
                <Typography variant="subtitle1" className={classes.subtitle}>
                  Observed value
                </Typography>
                <TextField
                  variant="outlined"
                  value={currentValueTextOriginal}
                  style={{ width: '100%' }}
                  InputProps={{ readOnly: true }}
                />
              </div>
              <div className={classes.column}>
                <Typography variant="subtitle1" className={classes.subtitle}>
                  Amended value
                </Typography>
                <FormControl className={classes.formControl}>
                  {options ? (
                    <Select
                      value={nullSelected ? '' : value}
                      disabled={nullSelected}
                      onChange={handleChange}
                      variant="outlined"
                      displayEmpty
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.title}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <TextField
                      variant="outlined"
                      type="number"
                      value={nullSelected ? '' : value}
                      error={isValid ? !isValid(`${value}`) : false}
                      helperText={helpText ?? ''}
                      onChange={handleChange}
                      disabled={nullSelected}
                      InputProps={{
                        endAdornment: units ? <InputAdornment position="end">{units}</InputAdornment> : undefined,
                      }}
                    />
                  )}
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={nullSelected}
                      classes={checkBoxClasses}
                      onChange={(event) => {
                        setNullSelected(event.target.checked);
                        if (event.target.checked) {
                          setOldValue(
                            location?.newObservation.object && location?.newObservation.object[field] != null
                              ? (location?.newObservation.object[field] as number)
                              : (currentValue as number),
                          );
                          handleChange(null);
                        } else {
                          handleChange(oldValue);
                        }
                      }}
                    />
                  }
                  label={
                    <Typography variant="h6" style={{ color: theme.palette.common.neutralDark /* neutralDark */ }}>
                      Send a null value
                    </Typography>
                  }
                />
              </div>
            </div>
            <div className={classes.section}>
              <Typography variant="subtitle1" className={classes.subtitle}>
                Add note (optional)
              </Typography>
              <PrettierTextArea
                minRows={5}
                className={`${classes.textArea} ${classes.textAreaText}`}
                paperClassName={classes.textArea}
                value={location?.newObservation.object?.newNote?.note ?? ''}
                paperStyle={{ backgroundColor: theme.palette.common.neutralXXLight }}
                onChange={(event) => {
                  if (onChangeNote) onChangeNote(event.target.value);
                }}
              />
            </div>
            <div
              className={classes.section}
              style={{ display: 'grid', width: '100%', gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr' }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    classes={checkBoxClasses}
                    onChange={(event) => {
                      if (onChangeNotify) onChangeNotify(event.target.checked);
                    }}
                  />
                }
                label={
                  <Typography variant="h6" style={{ color: theme.palette.common.neutralDark /* neutralDark */ }}>
                    Notify observer {observerName}
                  </Typography>
                }
              />

              <Button
                variant="contained"
                style={{ padding: theme.spacing(1), color: theme.palette.common.white, backgroundColor: 'grey' }}
                onClick={handleSubmit}
              >
                Confirm amendment
                {location?.newObservation.status === 'loading' && (
                  <CircularProgress size={16} aria-valuetext="loading" />
                )}
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

EditFieldDialog.displayName = 'EditFieldDialog';
export default EditFieldDialog;
