/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import {
  Theme,
  Grid,
  Divider,
  Typography,
  Dialog,
  DialogContent,
  CircularProgress,
  LinearProgress,
  TextField,
} from '@material-ui/core';
import { DataGrid, GridColDef, FilterModel } from '@material-ui/data-grid';
import { ArrowDropDown, Cancel, Close, Schedule, Done, GetApp } from '@material-ui/icons';

import OptionButton from 'components/OptionButton';

import { ButtonTabs, CustomDateTimePicker, PrimaryButton } from 'components';
import { ArchiveManager, Common, MapLayerManager } from 'models';

import { useAppSelector, useAppDispatch } from 'hooks';
import { ArchiveActions } from 'state/archive';
import { getUserState, toTimeDDMonYearFormat } from 'utils';

const locationNameFilterModel: FilterModel = {
  items: [{ columnField: 'locationName', operatorValue: 'contains', value: '' }],
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      color: theme.palette.text.primary,
    },
    subTitle: {
      marginBottom: theme.spacing(2),
      color: theme.palette.common.neutralDark,
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.5),
    },
    buttonContainer: {
      marginBottom: theme.spacing(5),
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: '1fr auto',
    },
    filterSection: {
      margin: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
    dateTimePickerText: {
      cursor: 'pointer',
      fontSize: 18,
      textDecoration: 'underline',
      color: theme.palette.common.black70,
    },
    closeIcon: {
      width: 20,
      height: 20,
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      cursor: 'pointer',
    },
    downloadItem: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
      borderBottom: `${theme.palette.common.neutralLight} 1px solid`,
    },
    downloadItemText: {
      color: theme.palette.common.black70,
      fontWeight: 'bold',
    },
    paddedField: {
      marginBottom: theme.spacing(2),
    },
  }),
);

const juristictionNameMap: { id: Common.Jurisdictions | 'all' | null; name: string }[] = [
  { id: 'all', name: 'All' },
  { id: 'act', name: 'Australian Capital Territory' },
  { id: 'nsw', name: 'New South Wales' },
  { id: 'nt', name: 'Northern Territory' },
  { id: 'qld', name: 'Queensland' },
  { id: 'sa', name: 'South Australia' },
  { id: 'tas', name: 'Tasmania' },
  { id: 'vic', name: 'Victoria' },
  { id: 'wa', name: 'Western Australia' },
];

const mapTypesMap: { id: MapLayerManager.MapLayer.Type | null; name: string }[] = [
  { id: null, name: 'All' },
  { id: 'grass-curing', name: 'Grass Curing' },
  { id: 'grass-fuel-condition', name: 'Grass Fuel Condition' },
  { id: 'grass-fuel-load', name: 'Grass Fuel Load' },
  { id: 'time-since-fire', name: 'Time Since Fire' },
  { id: 'fuel-type', name: 'Fuel Type' },
];

const mapTypeColumns: GridColDef[] = [
  {
    field: 'type',
    headerName: 'Type',
    type: 'string',
    width: 180,
  },
  {
    field: 'jurisdiction',
    headerName: 'Jurisdiction',
    type: 'string',
    width: 120,
  },
  {
    field: 'id',
    headerName: 'Filename',
    type: 'string',
    width: 380,
    flex: 1,
  },
  {
    field: 'date',
    headerName: 'Created',
    type: 'dateTime',
    width: 180,
    align: 'right',
  },
];

const fuelTypeColumns: GridColDef[] = [
  {
    field: 'jurisdiction',
    headerName: 'Jurisdiction',
    type: 'string',
    width: 120,
  },
  {
    field: 'id',
    headerName: 'Filename',
    type: 'string',
    width: 380,
    flex: 1,
  },
  {
    field: 'date',
    headerName: 'Created',
    type: 'dateTime',
    width: 180,
    align: 'right',
  },
];

const observationColumns: GridColDef[] = [
  {
    field: 'locationName',
    headerName: 'Location Name',
    type: 'string',
    filterable: false,
    width: 160,
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    filterable: false,
    width: 110,
  },
  {
    field: 'observerName',
    headerName: 'Observer',
    type: 'string',
    filterable: false,
    width: 160,
  },
  {
    field: 'time',
    headerName: 'Created',
    type: 'dateTime',
    width: 180,
    filterable: false,
    align: 'right',
  },
];

function FSEExport() {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const { auth, archive } = useAppSelector((state) => state);

  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [status, setStatus] = useState<'idle' | 'loading' | 'finished' | 'error'>('idle');
  const [downloadStatus, setDownloadStatus] = useState<Record<string, 'idle' | 'loading' | 'finished' | 'error'>>({});
  const [selectedMapTifs, setSelectedMapTifs] = useState<string[]>([]);

  const [selectedJurisdiction, setSelectedJurisdiction] = useState<Common.Jurisdictions | 'all' | null>(null);
  const [selectedMapType, setSelectedMapType] = useState<MapLayerManager.MapLayer.Type | null>('grass-curing');

  const [filterCreatedBefore, setFilterCreatedBefore] = useState<Date | null>(null);
  const [filterCreatedAfter, setFilterCreatedAfter] = useState<Date | null>(null);
  const [filterLocation, setFilterLocation] = useState<string | undefined>(undefined);

  const selectedJurisdictionName = juristictionNameMap.find((x) => x.id === selectedJurisdiction)?.name ?? null;

  const selectedMapTypeName = mapTypesMap.find((x) => x.id === selectedMapType)?.name ?? null;

  const tabs: string[] = ['Maps', 'Observations', 'Fuel Type Models'];

  const [activeTab, setActiveTabRaw] = useState<string>(tabs[0]);

  const setActiveTab = (tab: string) => {
    setActiveTabRaw(tab);
    setSelectedMapTifs([]);
  };

  const handleSelectState = (index: number, value: string) => {
    const newState = juristictionNameMap.find((x) => x.name === value);
    if (newState) setSelectedJurisdiction(newState.id);
  };

  const handleSelectMapType = (index: number, value: string) => {
    const newValue = mapTypesMap.find((x) => x.name === value);
    if (newValue) setSelectedMapType(newValue.id);
  };

  const handleChangeLocationFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event);
    setFilterLocation(event.target.value);
  };

  const getFilteredObservationsList = (): ArchiveManager.FlatObservation[] => {
    let filteredObs: ArchiveManager.FlatObservation[] = [];

    if (archive.observations.status === 'finished' && archive.observations.object) {
      if (filterLocation) {
        filteredObs = [...archive.observations.object].filter(
          (x) => x.locationName.toLowerCase().indexOf(filterLocation.toLowerCase()) !== -1,
        );
      } else {
        filteredObs = [...archive.observations.object];
      }
    }

    return filteredObs;
  };

  useEffect(() => {
    if (auth.status === 'finished' && auth.object) {
      setSelectedJurisdiction(getUserState(auth.object));
    }
  }, [auth.status]);

  useEffect(() => {
    if (activeTab === 'Maps') {
      dispatch(
        ArchiveActions.getMaps({
          filter: {
            jurisdiction: selectedJurisdiction ?? undefined,
            type: selectedMapType ?? undefined,
            start: filterCreatedAfter ?? undefined,
            end: filterCreatedBefore ?? undefined,
          },
        }),
      );
    } else if (activeTab === 'Observations') {
      dispatch(
        ArchiveActions.getObservations({
          filter: {
            jurisdiction: selectedJurisdiction ?? undefined,
            start: filterCreatedAfter ?? undefined,
            end: filterCreatedBefore ?? undefined,
          },
        }),
      );
    } else if (activeTab === 'Fuel Type Models') {
      dispatch(
        ArchiveActions.getFuelTypes({
          filter: {
            jurisdiction: selectedJurisdiction ?? undefined,
            start: filterCreatedAfter ?? undefined,
            end: filterCreatedBefore ?? undefined,
          },
        }),
      );
    }
  }, [selectedJurisdiction, selectedMapType, filterCreatedBefore, filterCreatedAfter, activeTab]);

  const handleDownload = async () => {
    if (selectedMapTifs.length > 0) {
      setStatus('loading');

      let mockDownloadStatus = { ...downloadStatus };

      try {
        for (const id of selectedMapTifs) {
          try {
            mockDownloadStatus = { ...mockDownloadStatus, [id]: 'loading' };
            setDownloadStatus(mockDownloadStatus);
            const blob = await dispatch(ArchiveActions.downloadTiff({ file: id }));

            if (blob) {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = id;

              document.body.appendChild(a);
              a.click();
              a.remove();

              mockDownloadStatus = { ...mockDownloadStatus, [id]: 'finished' };
              setDownloadStatus(mockDownloadStatus);
            } else {
              mockDownloadStatus = { ...mockDownloadStatus, [id]: 'error' };
              setDownloadStatus(mockDownloadStatus);
            }
          } catch (e) {
            mockDownloadStatus = { ...mockDownloadStatus, [id]: 'error' };
            setDownloadStatus(mockDownloadStatus);
          }
        }

        setStatus('finished');
        setDownloadStatus({});
        setDownloadModalOpen(false);
      } catch (e) {
        setStatus('error');
      }
    }
  };

  return (
    <div style={{ overflow: 'hidden', display: 'grid', gridTemplateRows: 'auto auto 1fr auto' }}>
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography variant="h4" className={classes.title}>
            <span style={{ color: theme.palette.common.black70 }}>Export</span>
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10} style={{ display: 'grid', gridTemplateRows: 'auto auto 1fr' }}>
          <div className={classes.buttonContainer} style={{ marginBottom: theme.spacing(0.5) }}>
            <ButtonTabs
              activeIndex={activeTab}
              options={tabs}
              onClick={(option) => {
                setActiveTab(option as string);
              }}
            />
            {activeTab === 'Observations' && (
              <TextField
                className={classes.paddedField}
                label="Location Filter"
                variant="standard"
                value={filterLocation}
                onChange={handleChangeLocationFilter}
              />
            )}
          </div>
          <div style={{ display: 'grid', gridAutoFlow: 'column', marginBottom: theme.spacing(1) }}>
            <div className={classes.filterSection}>
              <CustomDateTimePicker
                className={classes.dateTimePickerText}
                text={filterCreatedAfter ? toTimeDDMonYearFormat(filterCreatedAfter) : 'Click to set start date'}
                onChange={(newDate) => setFilterCreatedAfter(newDate)}
              />
              {filterCreatedAfter && (
                <Cancel className={classes.closeIcon} onClick={() => setFilterCreatedAfter(null)} />
              )}
            </div>
            <div className={classes.filterSection}>
              <CustomDateTimePicker
                className={classes.dateTimePickerText}
                text={filterCreatedBefore ? toTimeDDMonYearFormat(filterCreatedBefore) : 'Click to set end date'}
                onChange={(newDate) => setFilterCreatedBefore(newDate)}
              />
              {filterCreatedBefore && (
                <Cancel className={classes.closeIcon} onClick={() => setFilterCreatedBefore(null)} />
              )}
            </div>
            {activeTab === 'Maps' && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <OptionButton
                  style={{ minWidth: 32, fontSize: 18 }}
                  options={mapTypesMap.map((x) => x.name)}
                  onChange={handleSelectMapType}
                >
                  <Typography variant="h6">{selectedMapTypeName ?? 'Not Selected'}</Typography> <ArrowDropDown />
                </OptionButton>
              </div>
            )}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <OptionButton
                style={{ minWidth: 32, fontSize: 18 }}
                options={juristictionNameMap.map((x) => x.name)}
                onChange={handleSelectState}
              >
                <Typography variant="h6">{selectedJurisdictionName ?? 'Not Selected'}</Typography> <ArrowDropDown />
              </OptionButton>
            </div>
          </div>
          {activeTab === 'Maps' && (
            <div style={{ height: '100%', width: '100%', paddingBottom: theme.spacing(1) }}>
              <DataGrid
                rows={(archive.maps.status === 'finished' && archive.maps.object) || []}
                columns={mapTypeColumns}
                loading={archive.maps.status === 'loading'}
                checkboxSelection
                autoPageSize
                disableColumnMenu
                onSelectionModelChange={(newSelection) => {
                  setSelectedMapTifs([...(newSelection.selectionModel as string[])]);
                }}
              />
            </div>
          )}
          {activeTab === 'Observations' && (
            <div style={{ height: '100%', width: '100%', paddingBottom: theme.spacing(1) }}>
              <DataGrid
                rows={getFilteredObservationsList()}
                columns={observationColumns}
                loading={archive.observations.status === 'loading'}
                filterModel={locationNameFilterModel}
                checkboxSelection
                autoPageSize
                disableColumnMenu
                onSelectionModelChange={(newSelection) => {
                  setSelectedMapTifs([...(newSelection.selectionModel as string[])]);
                }}
              />
            </div>
          )}
          {activeTab === 'Fuel Type Models' && (
            <div style={{ height: '100%', width: '100%', paddingBottom: theme.spacing(1) }}>
              <DataGrid
                rows={(archive.fuelTypes.status === 'finished' && archive.fuelTypes.object) || []}
                columns={fuelTypeColumns}
                loading={archive.fuelTypes.status === 'loading'}
                checkboxSelection
                autoPageSize
                disableColumnMenu
                onSelectionModelChange={(newSelection) => {
                  setSelectedMapTifs([...(newSelection.selectionModel as string[])]);
                }}
              />
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: theme.spacing(2) }}>
            <PrimaryButton
              onClick={() => {
                if (activeTab === 'Observations') {
                  if (selectedMapTifs.length > 0 && archive.observations.object) {
                    const selectedObs: ArchiveManager.FlatObservation[] = [];
                    for (let i = 0; i < archive.observations.object.length; i += 1) {
                      if (selectedMapTifs.includes(String(archive.observations.object[i].id))) {
                        selectedObs.push(archive.observations.object[i]);
                      }
                    }

                    const csvContent = ArchiveManager.toCSV(selectedObs);
                    const link = window.document.createElement('a');
                    link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csvContent)}`);
                    link.setAttribute('download', 'observations.csv');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }
                } else {
                  const initDownloadState: typeof downloadStatus = {};
                  selectedMapTifs.forEach((id) => {
                    initDownloadState[id] = 'idle';
                  });
                  setDownloadStatus(initDownloadState);
                  setDownloadModalOpen(true);
                }
              }}
              disabled={selectedMapTifs == null || selectedMapTifs.length === 0}
            >
              Download
            </PrimaryButton>
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={downloadModalOpen}
        onClose={() => setDownloadModalOpen(false)}
        fullWidth
        maxWidth="md"
        aria-labelledby="Download"
        aria-describedby="Manages the downloads of map tifs"
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: theme.spacing(2) }}>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {status === 'loading' ? 'Downloads in progress' : 'Confirm files'}
          </Typography>
          <Close style={{ cursor: 'pointer', marginLeft: 'auto' }} onClick={() => setDownloadModalOpen(false)} />
        </div>
        <LinearProgress
          variant="determinate"
          value={
            (selectedMapTifs.reduce((acc, id) => (downloadStatus[id] === 'finished' ? acc + 1 : acc), 0) /
              selectedMapTifs.length) *
            100
          }
          style={{ height: theme.spacing(1), margin: theme.spacing(1), marginTop: 0 }}
        />
        <DialogContent>
          <div>
            {selectedMapTifs.map((id) => (
              <div key={id} className={classes.downloadItem}>
                <Typography variant="subtitle1" className={classes.downloadItemText}>
                  {id}
                </Typography>
                {downloadStatus[id] === 'idle' && <Schedule style={{ color: theme.palette.common.neutralDark }} />}
                {downloadStatus[id] === 'loading' && (
                  <CircularProgress
                    size={24}
                    style={{ color: theme.palette.common.neutralDark }}
                    aria-valuetext="loading"
                  />
                )}
                {downloadStatus[id] === 'finished' && <Done style={{ color: theme.palette.common.green }} />}
                {downloadStatus[id] === 'error' && <Close style={{ color: theme.palette.common.red }} />}
              </div>
            ))}
          </div>
        </DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: theme.spacing(1),
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(2),
          }}
        >
          <PrimaryButton onClick={handleDownload} disabled={status === 'loading'}>
            <GetApp /> Start Downloads
          </PrimaryButton>
        </div>
      </Dialog>
    </div>
  );
}

export default FSEExport;
