import React, { useState } from 'react';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import {
  CardContent,
  Card,
  Button,
  TextField,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from '@material-ui/core';
import { Edit, Close } from '@material-ui/icons';
import { customColors } from 'theme';

import { FireAreaManager, MapLayerManager } from 'models';
import { MapLayer } from 'models/maplayer';
import OptionButton from '../OptionButton';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
      verticalAlign: 'middle',
      marginTop: theme.spacing(1),
    },
    close: {
      marginLeft: 'auto',
      color: theme.palette.common.neutralDark, // neutralDark
      textDecoration: 'underline',
      position: 'absolute',
      right: 0,
      top: 0,
      margin: 8,
    },
    button: {
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
      fontWeight: 'bold',
      width: '-webkit-fill-available',
    },
    grid: {
      padding: theme.spacing(),
    },
  }),
);

interface IProps {
  fireArea: FireAreaManager.FireArea;
  layer: MapLayerManager.MapLayer;
  onStageOperation: (operation: MapLayerManager.MapLayer.StagedOperation) => void;
}

const options = [
  'Set fire management district',
  'Translate fire management district',
  'Set location',
  'Translate location',
];

const CuringEditor: React.FunctionComponent<IProps> = ({ fireArea, layer, onStageOperation }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [editingValue, setEditing] = useState(false);
  const [settingValue, setSetting] = useState('50');
  const [editingOption, setEditingOption] = useState(0);
  const [selectedLocationValue, setSelectedLocation] = useState(fireArea.locations[0]?.id || 0);
  const [radiusValue, setRadius] = useState('1000');

  const createOperation = (): MapLayer.LayerOperation => {
    const value = parseInt(settingValue, 10);
    switch (editingOption) {
      case 0:
        return {
          input_id: fireArea.id,
          input_type: 'FireManagementArea',
          operation_type: 'set',
          operation_value: value,
          note: `Setting the ${fireArea.name} fire management district to ${value}`,
        };
      case 1:
        return {
          input_id: fireArea.id,
          input_type: 'FireManagementArea',
          operation_type: 'translate',
          operation_value: value,
          note: `Translating the ${fireArea.name} fire management district by ${value}`,
        };
      case 2:
        return {
          input_id: selectedLocationValue,
          input_type: 'Location',
          operation_type: 'set',
          operation_value: value,
          input_buffer: radiusValue,
          note: `Setting the ${
            fireArea.locations.find((x) => x.id === selectedLocationValue)?.name
          } location to ${value}`,
        };
      case 3:
        return {
          input_id: selectedLocationValue,
          input_type: 'Location',
          operation_type: 'translate',
          operation_value: value,
          input_buffer: radiusValue,
          note: `Translating the ${
            fireArea.locations.find((x) => x.id === selectedLocationValue)?.name
          } location by ${value}`,
        };
      default:
        throw new Error('layer not known');
    }
  };

  const handleSubmit = () => {
    const operation = createOperation();
    onStageOperation({ layer, fireArea, operation });
    setEditing(false);
  };

  if (!editingValue) {
    return (
      <Button
        variant="outlined"
        onClick={() => setEditing(true)}
        style={{
          color: theme.palette.common.neutralDark,
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '40px',
        }}
      >
        <Edit /> Edit Curing
      </Button>
    );
  }

  const renderLocationPicker = () => {
    return (
      <Grid container justify="space-between">
        <Grid item sm={6} className={classes.grid}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">Location</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectedLocationValue}
              onChange={(e) => setSelectedLocation(e.target.value as any)}
              label="Location"
            >
              {fireArea.locations.map((x) => (
                <MenuItem value={x.id}>{x.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={6} className={classes.grid}>
          <TextField
            label="Radius"
            size="small"
            variant="outlined"
            value={radiusValue}
            InputProps={{
              endAdornment: <InputAdornment position="end">m</InputAdornment>,
            }}
            fullWidth
            onChange={(e) => setRadius(e.target.value)}
          />
        </Grid>
      </Grid>
    );
  };

  const renderInputAndButton = () => {
    return (
      <Grid container justify="space-between">
        <Grid item sm={8} className={classes.grid}>
          <TextField
            label="Value"
            size="small"
            variant="outlined"
            value={settingValue}
            fullWidth
            onChange={(e) => setSetting(e.target.value)}
          />
        </Grid>
        <Grid item sm={4} className={classes.grid}>
          <Button variant="contained" className={classes.button} onClick={handleSubmit}>
            Apply
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderLabel = (text: string) => {
    return (
      <Typography style={{ color: theme.palette.common.neutralDark, fontSize: 14, marginBottom: 16 }}>
        {text}
      </Typography>
    );
  };

  const renderForm = () => {
    switch (editingOption) {
      case 0:
        return (
          <>
            {renderLabel('Set the whole fire management to a uniform value (0 to 100)')}
            {renderInputAndButton()}
          </>
        );
      case 1:
        return (
          <>
            {renderLabel('Translate the whole fire management by a percentage (-100 to 100)')}
            {renderInputAndButton()}
          </>
        );
      case 2:
        return (
          <>
            {renderLabel('Set the location to a uniform value (0 to 100)')}
            {renderLocationPicker()}
            {renderInputAndButton()}
          </>
        );
      case 3:
        return (
          <>
            {renderLabel('Translate the location by a percentage (-100 to 100)')}
            {renderLocationPicker()}
            {renderInputAndButton()}
          </>
        );
      default:
        return <>unknown option {editingOption}</>;
    }
  };

  return (
    <Card>
      <div style={{ position: 'relative', color: theme.palette.common.neutralDark, fontSize: 14 }}>
        <div style={{ display: 'inline-block', borderRight: `1px solid ${customColors.neutralDark}`, padding: 16 }}>
          Edit curing
        </div>
        <div style={{ display: 'inline-block', padding: '10px 16px', color: 'black' }}>
          {options[editingOption]} <OptionButton options={options} onChange={setEditingOption} />
        </div>
        <Button className={classes.close} onClick={() => setEditing(false)} variant="text">
          <Close /> Close
        </Button>
      </div>
      <CardContent style={{ borderTop: `1px solid ${customColors.neutralDark}`, minHeight: 170 }}>
        {renderForm()}
      </CardContent>
    </Card>
  );
};

export default CuringEditor;
