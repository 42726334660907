/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useParams, Route, Link as RouterLink, useNavigate, Routes } from 'react-router-dom';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import {
  Theme,
  Drawer,
  Toolbar,
  Typography,
  Link,
  CircularProgress,
  Button,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight, Close, Layers } from '@material-ui/icons';

import { useAppSelector, useAppDispatch } from 'hooks';
import { EditorActions } from 'state/editor';
import { ToastActions } from 'state/toast';
import { LayerActions } from 'state/layers';
import { FuelTypeModelActions } from 'state/fueltypemodels';

import {
  Map,
  FloatingMapButton,
  EditorWorkflowInitComponent,
  WorkflowAreaList,
  WorkflowSubmit,
  WorkflowAreaDetails,
  WorkflowNotes,
  Toggle,
  AreYouSureDialog,
  MapOverlay,
  LayerList,
} from 'components';
import {
  MapCommand,
  AddWmsTileLayer,
  AddWmsMvtLayer,
  ZoomToBounds,
  Resize,
  AddScaleLine,
  MouseCoords,
  MouseClick,
  ShowPopup,
} from 'components/map/MapCommands';
import { FireAreaManager, LayerManager, MapLayerManager, ToastManager, WmsManager } from 'models';
import { formatValue, formatDifferenceValue, getUserState } from 'utils';

import Legend from 'components/map/Legend';
import OpacitySlider from 'components/map/OpacitySlider';
import config from 'config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    map: {
      width: '100%',
      height: '100%',
    },
    drawer: {
      width: '50%',
      flexShrink: 0,
    },
    drawerPaper: {
      width: '50%',
    },
    drawerContent: {
      padding: theme.spacing(0),
      display: 'grid',
      gridTemplateRows: '1fr auto',
      overflowY: 'hidden',
      height: '100%',
    },
    drawerScrollable: {
      padding: theme.spacing(1),
      overflowY: 'auto',
    },
    drawerBottom: {
      padding: theme.spacing(0),
      borderTop: `1px solid ${theme.palette.common.neutralLight}`,
      backgroundColor: theme.palette.common.white,
      display: 'grid',
      placeItems: 'center',
      gridAutoFlow: 'column',
    },
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(5),
      color: theme.palette.text.primary,
    },
    subtitle: {
      fontWeight: 'bold',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      color: theme.palette.common.neutralDark, // neutralDark
    },
    sidebarfab: {
      color: theme.palette.common.grey,
      transform: 'rotate(90deg)',
      width: 'max-content',
      marginTop: 50,
    },
    sidebarfabicon: {
      color: theme.palette.common.grey,
    },
    button: {
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
      margin: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
      marginLeft: 0,
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
      fontWeight: 'bold',
      minWidth: 175,
      width: '-webkit-fill-available',
      // @ts-ignore This is for firefox
      width: '-moz-available',
    },
    link: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: `${theme.spacing(1)}px 0px`,
      padding: theme.spacing(2),
      border: `1px solid ${theme.palette.common.neutralXLight}`,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.neutralXLight,
        cursor: 'pointer',
      },
    },
    linkTitle: {
      fontWeight: 'bold',
    },
    linkSubtitle: {
      fontWeight: 'bold',
    },
    linkArrow: {
      color: theme.palette.common.neutral,
    },
    content: {
      flexGrow: 1,
      transition: `${theme.transitions.create('left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })}, ${theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })}, ${theme.transitions.create('top', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })}`,
      left: '0%',
    },
    contentShift: {
      transition: `${theme.transitions.create('left', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })}, ${theme.transitions.create('height', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })}, ${theme.transitions.create('top', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })}`,
      left: '50%',
    },
    layersFAB: {
      flexGrow: 1,
      filter: `drop-shadow(0px 2px 4px ${theme.palette.common.neutral})`,
    },
    popupTitle: {
      color: theme.palette.common.neutralDark,
    },
  }),
);

function FSEEditorWorkFlow() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { layerId: layerIdRaw } = useParams<{ layerId: string }>();
  const { auth, editor, layers } = useAppSelector((state) => state);
  const { maplayers, fireareas, layerOperations } = editor;

  const layerId = layerIdRaw ?? '';

  const layer = maplayers.object && maplayers.object.find((l) => l.id === +layerId);
  const operations = layerOperations.filter((x) => x.layer.id === layer?.id);
  const fireAreaList = layer ? fireareas[layer.id] : undefined;
  const { local } = useAppSelector((state) => state.fuelTypeModels);
  const [drawerOpen, setDrawerOpen] = useState(true);

  const selectedFireAreaIdFromURL = /\/area\/(\d+)/.exec(window.location.pathname);
  const selectedFireAreaFromURL = selectedFireAreaIdFromURL
    ? fireAreaList?.object?.find((a) => +selectedFireAreaIdFromURL[1] === a.id)
    : null;

  const userJurisdiction = auth.object ? getUserState(auth.object) : null;

  const mainLayer =
    userJurisdiction && layer?.type ? `fse-${userJurisdiction}:${layer.type}-candidate-${userJurisdiction}` : null;
  const diffLayer =
    userJurisdiction && layer?.type ? `fse-${userJurisdiction}:${layer.type}-difference-${userJurisdiction}` : null;

  const fireManagementDistrictLayer = LayerManager.findLayer('FireManagementDistricts', LayerManager.layerData);

  const [generalManagerOpen, setGeneralManagerOpen] = useState(false);

  const [selectedFireArea, setSelectedFireArea] = useState<FireAreaManager.FireArea | null>(null);

  const [submitNote, setSubmitNote] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmEditOpen, setConfirmEditOpen] = useState(false);
  const [selectedLayerId, setSelectedLayerId] = useState(mainLayer);

  const [mouseClick, setMouseClick] = useState<MouseClick>();
  const [featureLayerCommand, setFeatureLayerCommand] = useState<AddWmsTileLayer>();
  const [boundaryLayerCommand, setBoundaryLayerCommand] = useState<AddWmsMvtLayer>();
  const [mapDispatch, setMapDispatch] = useState<{ dispatch: (command: MapCommand) => void }>();

  const fireWeatherAreaOperations = operations.filter((op) => op.fireArea.id === selectedFireArea?.id);

  const selectLayerLookup = layer?.type ? MapLayerManager.getMapLayerName(layer.type) : '';
  const selectedLayer: LayerManager.Layer | null = selectedLayerId
    ? ({
        ...LayerManager.layerData.find((lyr) => lyr.name === selectLayerLookup),
        id: selectedLayerId as LayerManager.Layer.LayerIds,
        serviceName: selectedLayerId,
        status: 'normal',
        timeSteps: [],
        lastUpdated: Date.now(),
      } as LayerManager.Layer)
    : null;

  useEffect(() => {
    setSelectedFireArea(selectedFireAreaFromURL || null);
  }, [selectedFireAreaFromURL]);

  useEffect(() => {
    if (maplayers.status === 'idle' && auth.status === 'finished') dispatch(EditorActions.getLayers());
  }, [dispatch, maplayers.status, auth.status]);

  useEffect(() => {
    const selectedBoundaryLayer = layers.layers.object?.find((l) => l.id === layers.selectedBoundary);
    boundaryLayerCommand?.update({
      layerName: selectedBoundaryLayer != null ? selectedBoundaryLayer.serviceName : null,
      labelField: selectedBoundaryLayer != null ? selectedBoundaryLayer.mvtLabelField : null,
      auth: auth.object,
    });
  }, [layers.selectedBoundary, boundaryLayerCommand, layers.layers.object, auth.object]);

  useEffect(() => {
    if (layer && (fireAreaList == null || fireAreaList?.status === 'idle') && auth.status === 'finished')
      dispatch(EditorActions.getFireAreas({ layerId: layer.id }));
  }, [auth.status, dispatch, fireAreaList, fireAreaList?.status, layer]);

  useEffect(() => {
    if (layers.layers.status === 'idle' && auth.status === 'finished') dispatch(LayerActions.getLayers());
  }, [layers.layers.status, auth.status]);

  useEffect(() => {
    if (auth.status === 'finished' && auth.object)
      featureLayerCommand?.update({
        layerName: selectedLayerId,
        date: layer ? new Date(layer.created) : null,
        auth: auth.object,
      });

    if (maplayers.status === 'finished' && selectedLayerId == null) setSelectedLayerId(mainLayer);
  }, [dispatch, auth.status, maplayers.status, selectedLayerId]);

  useEffect(() => {
    if (local.status === 'idle' && auth.status === 'finished') {
      if (auth?.object) {
        const userState = getUserState(auth?.object);
        dispatch(FuelTypeModelActions.getFuelTypeModelsForJurisdiction({ jurisdiction: userState || '' }));
      }
    }
  }, [local.status, auth.status]);

  const handleMapRegistration = (mapDispatcher: (command: MapCommand) => void) => {
    setMapDispatch({ dispatch: mapDispatcher });
    mapDispatcher(new AddScaleLine());
    mapDispatcher(new MouseCoords());
    const displayLayerCommand = new AddWmsTileLayer({
      layerName: mainLayer,
      date: layer ? new Date(layer.created) : null,
      auth: auth.object,
      useUserState: true,
    });
    setFeatureLayerCommand(displayLayerCommand);
    mapDispatcher(displayLayerCommand);
    const mc = new MouseClick(() => {});
    setMouseClick(mc);
    mapDispatcher(mc);
    const mvtLayer = new AddWmsMvtLayer({
      layerName: fireManagementDistrictLayer?.serviceName ?? null,
      labelField: fireManagementDistrictLayer?.mvtLabelField ?? null,
      auth: auth.object,
    });
    setBoundaryLayerCommand(mvtLayer);
    mapDispatcher(mvtLayer);
  };

  // Depends on local.object.data
  const formatFuelType = (value: number) => {
    if (local.object?.data) {
      const ftSet = local.object.data.find((_ftSet) => _ftSet.status === 'authorised');
      if (ftSet?.param_list) {
        const param = ftSet.param_list.find((_param) => _param.FTno_State === value);
        if (param?.Fuel_FDR) {
          return param?.Fuel_FDR;
        }
      }
    }
    return `(Fuel Type No.) ${value}`;
  };

  useEffect(() => {
    const handleMouseClick = async (coords: number[]) => {
      try {
        if (selectedLayer) {
          // Initially render loading text
          mapDispatch?.dispatch(new ShowPopup(coords, 'Loading...'));

          const query = await WmsManager.queryWMSValues([selectedLayer], coords, auth.object);
          if (layer) {
            if (query != null) {
              const value = query[selectedLayer.id];
              let formattedValue = '';
              if (value != null && !Number.isNaN(value) && value > -9000) {
                if (selectedLayerId !== mainLayer) {
                  formattedValue = formatDifferenceValue(layer, value, selectedLayer?.units);
                } else if (layer.name === 'Fuel Type' && local.status === 'finished') {
                  formattedValue = formatFuelType(value);
                } else {
                  formattedValue = formatValue(layer, value, selectedLayer?.units);
                }
              }
              mapDispatch?.dispatch(
                new ShowPopup(
                  coords,
                  formattedValue
                    ? `${layer.name}${selectedLayerId === mainLayer ? '' : ' Difference'}: ${formattedValue}`
                    : 'Cannot retrieve value',
                ),
              );
            } else {
              mapDispatch?.dispatch(new ShowPopup(coords, 'Cannot retrieve value'));
            }
          }
        }
      } catch {
        mapDispatch?.dispatch(new ShowPopup(coords, 'Cannot retrieve value'));
      }
    };
    mouseClick?.update(handleMouseClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapDispatch, layer, selectedLayerId, mouseClick, local.status]);

  useEffect(() => {
    if (auth.status === 'finished' && window.location.pathname === `/editor/layer/${layerId}`) {
      const jd = getUserState(auth.object);
      if (jd) mapDispatch?.dispatch(new ZoomToBounds(config.jurisdictionBounds[jd]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.status, mapDispatch, window.location.pathname]);

  const handleOpacity = (value: number) => {
    if (featureLayerCommand) {
      featureLayerCommand.setOpacity(value);
    }
  };

  const updateDrawer = (value: boolean) => {
    setDrawerOpen(value);
    if (mapDispatch) setTimeout(() => mapDispatch.dispatch(new Resize()), 500);
  };

  const onFireAreaSelected = (area: FireAreaManager.FireArea) => {
    setSelectedFireArea(area);
    boundaryLayerCommand?.highlight(area.name);
    if (mapDispatch) mapDispatch.dispatch(new ZoomToBounds(area.bounds));
  };

  const handleToggleLayerChange = (toggleValue: string) => {
    if (auth.object) {
      const isCandidateLayer = toggleValue === 'Candidate';
      setSelectedLayerId(isCandidateLayer ? mainLayer : diffLayer);

      featureLayerCommand?.update({
        layerName: isCandidateLayer ? mainLayer : diffLayer,
        date: layer ? new Date(layer.created) : null,
        auth: auth.object,
      });
    }
  };

  const handleRegenerateClick = () => {
    if (layer)
      dispatch(EditorActions.createNewAutoCandidateLayer({ layer }))
        .then(() => dispatch(EditorActions.getLayers()))
        .then(() => navigate('/editor/dashboard'));
  };

  const handleSubmit = async () => {
    if (layer)
      try {
        await dispatch(EditorActions.submitLayer({ layer, note: submitNote || undefined }));
        await dispatch(
          ToastActions.showToast({
            message: 'Submitted',
            type: ToastManager.ToastType.success,
          }),
        );
        navigate('/editor/dashboard');
      } catch (e: any) {
        await dispatch(
          ToastActions.showToast({
            message: e?.data?.detail || `Error when submitting`,
            type: ToastManager.ToastType.error,
          }),
        );
      }
  };

  const handleClearOperations = () => {
    if (layer) dispatch(EditorActions.clearOperations({ layer }));
  };

  const handleSubmitOperations = async () => {
    if (layer) {
      const operationsToSubmit = operations.filter((x) => x.layer.id === +layerId).map((x) => x.operation);

      try {
        await dispatch(EditorActions.regenerateLayer({ layer, operations: operationsToSubmit }));
        await dispatch(EditorActions.clearOperations({ layer }));
        await dispatch(
          ToastActions.showToast({
            message: 'Edits submitted',
            type: ToastManager.ToastType.success,
          }),
        );
        navigate('/editor/dashboard');
      } catch {
        await dispatch(
          ToastActions.showToast({
            message: 'Error sending edits to server',
            type: ToastManager.ToastType.error,
          }),
        );
      }
    }
  };

  const handleStageOperation = (operation: MapLayerManager.MapLayer.StagedOperation) => {
    dispatch(EditorActions.addOperations([operation]));
  };

  const handleClearAreaOperations = () => {
    if (layer) dispatch(EditorActions.clearOperations({ layer, fireArea: selectedFireArea ?? undefined }));
  };

  let backLocation = `/editor/layer/${layerId}`;
  if (window.location.pathname === `/editor/layer/${layerId}`) backLocation = `/editor/dashboard`;
  if (selectedFireAreaIdFromURL && selectedFireAreaIdFromURL[1] != null)
    backLocation = `/editor/layer/${layerId}/arealist`;
  return (
    <>
      <Drawer
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        onClose={() => updateDrawer(false)}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContent}>
          <div className={classes.drawerScrollable}>
            <div style={{ display: 'flex', margin: theme.spacing(2) }}>
              <Link
                component={RouterLink}
                to={backLocation}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ display: 'flex', alignItems: 'center', color: theme.palette.common.neutralDark }}
                >
                  <ChevronLeft /> Back
                </Typography>
              </Link>
            </div>
            {maplayers.status !== 'finished' ? (
              <div style={{ display: 'grid', placeItems: 'center' }}>
                <CircularProgress size={20} style={{ marginTop: theme.spacing(3) }} aria-valuetext="loading" />
              </div>
            ) : (
              <Routes>
                <Route
                  path={``}
                  element={<EditorWorkflowInitComponent layer={layer} onRegenerateClick={handleRegenerateClick} />}
                />

                <Route
                  path={`arealist`}
                  element={
                    <WorkflowAreaList
                      layer={layer}
                      fireAreas={fireAreaList}
                      operations={operations}
                      onSelectFireArea={onFireAreaSelected}
                    />
                  }
                />

                <Route
                  path={`area/:selectedFireArea`}
                  element={
                    <WorkflowAreaDetails
                      layer={layer}
                      fireArea={selectedFireArea}
                      status={fireAreaList?.status}
                      mapDispatch={mapDispatch?.dispatch}
                      layerId={mainLayer}
                      operations={fireWeatherAreaOperations}
                      onStageOperation={handleStageOperation}
                    />
                  }
                />

                <Route path={`notes`} element={<WorkflowNotes layer={layer} />} />

                <Route
                  path={`submit`}
                  element={<WorkflowSubmit layer={layer} onNoteChange={(note) => setSubmitNote(note)} />}
                />
              </Routes>
            )}
          </div>
          <div className={classes.drawerBottom}>
            <Routes>
              <Route
                path={`arealist`}
                element={
                  <>
                    {layer?.type === 'grass-curing' && (
                      <Button
                        className={classes.button}
                        style={{ marginLeft: theme.spacing(3) }}
                        variant="contained"
                        disabled={operations.length === 0}
                        onClick={handleClearOperations}
                      >
                        Clear Edits
                      </Button>
                    )}
                    <Button
                      className={classes.button}
                      variant="contained"
                      style={{ marginLeft: layer?.type !== 'grass-curing' ? theme.spacing(3) : undefined }}
                      disabled={!selectedFireArea}
                      onClick={() => navigate(`/editor/layer/${layerId}/area/${selectedFireArea?.id}`)}
                    >
                      View Details
                    </Button>
                    {layer?.type === 'grass-curing' && (
                      <Button
                        className={classes.button}
                        variant="contained"
                        disabled={operations.length === 0}
                        onClick={() => setConfirmEditOpen(true)}
                      >
                        Submit Edits
                      </Button>
                    )}
                    <AreYouSureDialog
                      open={confirmEditOpen}
                      onConfirm={() => {
                        setConfirmEditOpen(false);
                        if (handleSubmitOperations) handleSubmitOperations();
                      }}
                      onClose={() => setConfirmEditOpen(false)}
                    />
                  </>
                }
              />

              <Route
                path={`area/${selectedFireArea?.id}`}
                element={
                  layer?.type === 'grass-curing' && (
                    <Button
                      className={classes.button}
                      style={{ marginLeft: theme.spacing(3) }}
                      variant="contained"
                      onClick={handleClearAreaOperations}
                      disabled={fireWeatherAreaOperations.length === 0}
                    >
                      Clear edits in this district
                    </Button>
                  )
                }
              />

              <Route
                path={`submit`}
                element={
                  <>
                    <Button
                      className={classes.button}
                      style={{ marginLeft: theme.spacing(3) }}
                      disabled={layer?.updateStatus.status === 'loading'}
                      variant="contained"
                      onClick={() => setConfirmOpen(true)}
                    >
                      Submit for Authorisation
                      {layer?.updateStatus.status === 'loading' && (
                        <CircularProgress size={16} style={{ marginLeft: theme.spacing(2) }} aria-valuetext="loading" />
                      )}
                    </Button>
                    <AreYouSureDialog
                      open={confirmOpen}
                      onConfirm={() => {
                        setConfirmOpen(false);
                        if (handleSubmit) handleSubmit();
                      }}
                      onClose={() => setConfirmOpen(false)}
                    />
                  </>
                }
              />
            </Routes>
          </div>
        </div>
      </Drawer>
      <style>
        {`
        .ol-scale-line {
          background: white;
          filter: drop-shadow(0px 2px 4px ${theme.palette.common.neutral});
          padding: 4px;
          border-radius: 4px;
          bottom: 20px;
          left: 20px;
        }

        .ol-scale-line-inner {
          color: black;
          border: 2px solid black;
          border-top: none;
        }

        .ol-mouse-position {
          top: initial;
          pointer-events: auto;
          bottom: 20px;
          left: calc(50% - 80px);
          width: 160px;
          text-align: center;
          background-color: rgba(255,255,255,0.6);
          border-radius: 4px;
        }

        `}
      </style>
      <div
        className={`${classes.map}`}
        style={{ width: drawerOpen ? '50%' : '100%', marginLeft: drawerOpen ? '50%' : '0' }}
      >
        <Map basemap={layers.selectedBaseMap} shouldDisplay registerMapCommand={handleMapRegistration}>
          <FloatingMapButton
            onClick={() => setGeneralManagerOpen(true)}
            buttonStyle={{
              color: theme.palette.common.neutralDark,
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
            width="180px"
            height="30px"
            top="80px"
            left="calc(100% - 200px)"
            className={classes.layersFAB}
            style={{ borderRadius: 4 }}
          >
            <Typography
              variant="subtitle1"
              className={classes.popupTitle}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Layers /> Layer Settings
            </Typography>
          </FloatingMapButton>

          <MapOverlay
            top="120px"
            left="default"
            opacity={0}
            style={{
              right: '20px',
              boxShadow: 'none',
              textAlign: 'right',
            }}
          >
            <div>
              <Toggle values={['Candidate', 'Difference']} onChange={handleToggleLayerChange} />
            </div>
          </MapOverlay>

          <MapOverlay
            top="80px"
            left={drawerOpen ? 'calc(50% + 65px)' : '65px'}
            opacity={1.0}
            style={{
              overflowX: 'auto',
              whiteSpace: 'nowrap',
              filter: `drop-shadow(0px 2px 4px ${theme.palette.common.neutral})`,
            }}
          >
            <OpacitySlider onChange={handleOpacity} />
          </MapOverlay>

          <FloatingMapButton
            onClick={() => updateDrawer(!drawerOpen)}
            style={{
              borderRadius: '0 4px 4px 0',
            }}
            buttonStyle={{ flexDirection: 'column', justifyContent: 'flex-start' }}
            width="25px"
            height={drawerOpen ? '25px' : '150px'}
            top={`calc(50% - ${drawerOpen ? '12.5px' : '75px'})`}
            left={drawerOpen ? '50%' : '0%'}
            className={`${classes.content} ${drawerOpen ? classes.contentShift : ''}`}
          >
            {drawerOpen ? (
              <ChevronLeft className={classes.sidebarfabicon} />
            ) : (
              <>
                <ChevronRight className={classes.sidebarfabicon} />
                <div className={classes.sidebarfab}>Show side panel</div>
              </>
            )}
          </FloatingMapButton>

          <MapOverlay
            left="default"
            opacity={1.0}
            top="default"
            style={{
              right: '20px',
              bottom: '20px',
              overflowX: 'auto',
              whiteSpace: 'nowrap',
              filter: `drop-shadow(0px 2px 4px ${theme.palette.common.neutral})`,
            }}
          >
            <Legend layer={selectedLayer} />
          </MapOverlay>
        </Map>
      </div>
      <Dialog
        open={generalManagerOpen}
        onClose={() => setGeneralManagerOpen(false)}
        fullWidth
        maxWidth="md"
        aria-labelledby="Layer Manager"
        aria-describedby="Manages the layers for the selected map"
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: theme.spacing(2) }}>
          <Typography variant="h6" className={classes.popupTitle}>
            Basemaps &amp; Boundaries
          </Typography>
          <Close style={{ cursor: 'pointer', marginLeft: 'auto' }} onClick={() => setGeneralManagerOpen(false)} />
        </div>
        <DialogContent>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
            <LayerList columns={1} hideBookmark filterFn={(l) => l.isBaseMap} />
            <LayerList columns={1} hideBookmark filterFn={(l) => l.isBoundary} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default FSEEditorWorkFlow;
