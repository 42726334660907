import React from 'react';

import { LocationManager, curingCarouselSlides, fuelConditionCarouselSlides } from 'models';
import { isValidFuelLoad, getFullName } from 'utils';
import EditFieldDialog from './validator/EditFieldDialog';

export type ClickableFields = 'curing' | 'fuelLoad' | 'fuelCondition';
export type EditFieldDialogParams = Omit<Parameters<typeof EditFieldDialog>[0], 'open' | 'onClose'>;

export const openEditFieldDialog = ({
  option,
  location,
  setModalSettings,
  setModalOpen,
}: {
  option: ClickableFields;
  location: LocationManager.Location;
  setModalSettings: React.Dispatch<React.SetStateAction<EditFieldDialogParams | null>>;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const prevObservation = location.observations.find((obs) => obs.validated === true);
  const observation = location.observations[0];
  const observerName = getFullName(observation.observer);
  switch (option) {
    case 'curing':
      setModalSettings({
        locationId: location.id,
        fieldTitle: 'Grass Curing',
        field: 'curing',
        observationId: observation.id,
        options: curingCarouselSlides,
        currentValue: observation.curing ?? null,
        currentValueText: `${observation.curing ?? null}${observation.curing != null ? '%' : ''}`,
        currentValueTextOriginal: `${observation.curingOriginal ?? null}${
          observation.curingOriginal != null ? '%' : ''
        }`,
        prevValueText:
          prevObservation && `${prevObservation.curing ?? null}${prevObservation.curing != null ? '%' : ''}`,
        prevValueTime: prevObservation?.time,
        observerName,
      });
      setModalOpen(true);
      break;
    case 'fuelLoad':
      setModalSettings({
        locationId: location.id,
        fieldTitle: 'Fuel Load',
        field: 'fuelLoad',
        observationId: observation.id,
        currentValue: observation.fuelLoad ?? null,
        currentValueText: `${observation.fuelLoad ?? null}${observation.fuelLoad != null ? ' t/ha' : ''}`,
        currentValueTextOriginal: `${observation.fuelLoadOriginal ?? null}${
          observation.fuelLoadOriginal != null ? ' t/ha' : ''
        }`,
        prevValueText:
          prevObservation && `${prevObservation.fuelLoad ?? null}${prevObservation.fuelLoad != null ? ' t/ha' : ''}`,
        prevValueTime: prevObservation?.time,
        observerName,
        units: ' t/ha',
        isValid: isValidFuelLoad,
        helpText: 'Must be a valid number between 1 and 12',
      });
      setModalOpen(true);
      break;
    case 'fuelCondition':
      setModalSettings({
        locationId: location.id,
        fieldTitle: 'Fuel Condition',
        field: 'fuelCondition',
        observationId: observation.id,
        options: fuelConditionCarouselSlides,
        currentValue: observation.fuelCondition ?? null,
        currentValueText: LocationManager.fuelConditionText(observation.fuelCondition),
        currentValueTextOriginal: LocationManager.fuelConditionText(observation.fuelConditionOriginal),
        prevValueText: prevObservation && LocationManager.fuelConditionText(prevObservation.fuelCondition),
        prevValueTime: prevObservation?.time,
        observerName,
      });
      setModalOpen(true);
      break;
    default:
      setModalSettings(null);
      setModalOpen(false);
  }
};
