import { createReducer } from '@reduxjs/toolkit';

import { UserManager, Loadable } from 'models';
import { makeThunkFromAPICall } from 'state';

export type UserState = {
  users: Loadable<UserManager.User.UserList>;
  validators: Loadable<UserManager.User.UserList>;
  observers: Loadable<UserManager.User.UserList>;
  editUser: Loadable<UserManager.User.User>;
  upload: Loadable<null>;
};

const initialState: UserState = {
  users: {
    status: 'idle',
  },
  observers: {
    status: 'idle',
  },
  validators: {
    status: 'idle',
  },
  upload: {
    status: 'idle',
  },
  editUser: {
    status: 'idle',
  },
};

export const [getUsersForJurisdiction, setUsers] = makeThunkFromAPICall(
  UserManager.getUserListForJurisdiction,
  'users/users',
);

// quick hack to have two sets of users for the location admin page
export const [getValidatorsForJurisdiction, setValidators] = makeThunkFromAPICall(
  UserManager.getUserListForJurisdiction,
  'users/validators',
);

export const [getObserversForJurisdiction, setObservers] = makeThunkFromAPICall(
  UserManager.getUserListForJurisdiction,
  'users/observers',
);

export const [getEditUser, setEditUser] = makeThunkFromAPICall(UserManager.getEditUser, 'users/edit-users');

export const [addNewUser, newUser] = makeThunkFromAPICall(UserManager.addNewUser, 'users/new');

export const [updateUser, modUser] = makeThunkFromAPICall(UserManager.updateUser, 'users/update');

export const [deleteUser, delUser] = makeThunkFromAPICall(UserManager.deleteUser, 'users/delete');

export const userReducer = createReducer(initialState, (builder) => {
  builder.addCase(setUsers, (state, action) => {
    return { ...state, users: { ...state.users, ...action.payload.loadable } };
  });
  builder.addCase(setEditUser, (state, action) => {
    return { ...state, editUser: { ...state.editUser, ...action.payload.loadable } };
  });
  builder.addCase(setValidators, (state, action) => {
    return { ...state, validators: { ...state.validators, ...action.payload.loadable } };
  });
  builder.addCase(setObservers, (state, action) => {
    return { ...state, observers: { ...state.observers, ...action.payload.loadable } };
  });
});

export const UserActions = {
  getUsersForJurisdiction,
  getValidatorsForJurisdiction,
  getObserversForJurisdiction,
  getEditUser,
  addNewUser,
  updateUser,
  deleteUser,
};

export default userReducer;
